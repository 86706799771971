import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { mapTo, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import {
  OutRealEstateDTO,
  RealEstateDTO,
  RebuildEstateDTO,
  WithdrawalProductType,
  WithdrawDTO,
} from '@generated/generatedEntities';
import { RebuildEstateService } from '@app/core/services/rebuild/rebuild-estate.service';
import { EMPTY, Observable, of } from 'rxjs';
import { RealEstateService } from '@app/core/services/realEstate/real-estate.service';
import { RebuildEstateDataService } from '@app/core/services/rebuild/rebuild-estate-data/rebuild-estate-data.service';
import { RoomHeightDirectiveService } from '@app/calculators/components/room-height-directive/room-height-directive/room-height-directive.component';
import { IframeInput } from '@generated/extended';
import { Store } from '@ngrx/store';
import { RealEstateActions } from '@app/core/services/store/real-estate.actions';
import _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class LccDataService {
  private http = inject(HttpClient);
  private rebuildEstateService = inject(RebuildEstateService);
  private realEstateService = inject(RealEstateService);
  private rebuildEstateDataService = inject(RebuildEstateDataService);
  private _roomHeightDirectiveService = inject(RoomHeightDirectiveService);
  store = inject(Store);
  //TODO Due to current other data services are returning data - we do it here as well.
  // Ideally we return a boolean - so we route or not...
  getEmpty(): Observable<OutRealEstateDTO> {
    return this.http.get<OutRealEstateDTO>(`${environment.serverUrl}/api/rebuild_estate/empty`).pipe(
      tap((entity) => {
        var emptyRealEstate = _.cloneDeep(entity);
        emptyRealEstate.metaData.withdrawalProductType = 'LCC';
        emptyRealEstate.metaData.tasks = ['' as WithdrawalProductType, 'LCC', 'OPERATING_COSTS'];
        this.rebuildEstateDataService.prepareRebuildEmptyObject(emptyRealEstate);
        //this is not clean - potentially needs a rework.
        this._roomHeightDirectiveService.onChangeGeometryTotalFloorArea416TotalFloorArea416(emptyRealEstate);
        this.store.dispatch(RealEstateActions.updateRealEstate(_.cloneDeep(emptyRealEstate)));
        // this.store.dispatch(RealEstateActions.updateProgressOnRealEstate(entity));
        // return empty;
        mapTo(true);
      }),
    );
  }

  processEntity(entity: IframeInput) {
    console.warn('processEntity', entity);
    this.process(entity);
    return of(entity);
  }

  get(id: number) {
    return this.http.get<OutRealEstateDTO>(`${environment.serverUrl}/api/realestates/${id}`).pipe(
      tap((entity) => {
        this.process(entity as IframeInput);
      }),
    );
  }

  private process(entity: IframeInput) {
    try {
      this.rebuildEstateDataService.processEditRebuildRealEstate(entity);
    } catch (e) {
      console.error(e);
    }
    if (entity.requestId == null && entity.metaData.tasks.indexOf('OPERATING_COSTS') === -1) {
      entity.metaData.tasks.push('OPERATING_COSTS');
    }
    if (entity.metaData.tasks.indexOf('LCC') === -1) {
      entity.metaData.tasks.push('LCC');
    }
    entity.metaData.tasks.sort((a: WithdrawalProductType, b: WithdrawalProductType) => {
      if (a === 'BUILDING_COSTS_AND_DATES') {
        return -1;
      }
      if (b === 'BUILDING_COSTS_AND_DATES') {
        return 1;
      }
      if (a === 'REBUILD_COSTS') {
        return -1;
      }
      if (b === 'REBUILD_COSTS') {
        return 1;
      }
      if (a === 'LCC' && b === 'OPERATING_COSTS') {
        return 1;
      }
      if (a === 'OPERATING_COSTS' && b === 'LCC') {
        return -1;
      }
      return 0;
    });
    this.store.dispatch(RealEstateActions.updateRealEstate(JSON.parse(JSON.stringify(entity))));
    // this.store.dispatch(RealEstateActions.updateProgressOnRealEstate(entity));
  }

  serverSideValidation(realEstate: RebuildEstateDTO | RealEstateDTO): Observable<string> {
    return this.realEstateService.tryServerValidation(realEstate);
  }

  estimate(realEstate: RebuildEstateDTO | RealEstateDTO): Observable<WithdrawDTO> {
    if (realEstate.metaData.tasks.indexOf('REBUILD_COSTS') > -1) {
      return this.rebuildEstateService.estimateBuildingMetrics(realEstate);
    }
    if (realEstate.metaData.tasks.indexOf('OPERATING_COSTS') > -1) {
      return this.realEstateService.estimateBuildingMetrics(realEstate);
    }
    if (realEstate.metaData.tasks.indexOf('BUILDING_COSTS_AND_DATES') > -1) {
      return this.realEstateService.estimateBuildingMetrics(realEstate);
    }
    console.error('this should not happend');
    return EMPTY;
  }
}
