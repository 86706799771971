import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  NavigationEnd,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';

import { AuthenticationService } from '@app/auth/authentication.service';
import { Logger } from '@shared';
import { defaultIfEmpty, map, mergeMap, Observable, of, skipWhile, switchMap, take } from 'rxjs';
import { UserDTO } from '@generated/generatedEntities';
import { untilDestroyed } from '@ngneat/until-destroy';
import { CredentialsService } from '@app/auth/credentials.service';

const log = new Logger('AuthenticationGuard');

@Injectable({
  providedIn: 'root',
})
export class PublicIframeGuard implements CanActivate, CanLoad {
  private router = inject(Router);
  private authenticationService = inject(AuthenticationService);
  private credentialsService = inject(CredentialsService);

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let requestId = route.queryParams['requestId'];
    return this.isAuthenticated(requestId);
  }

  canLoad(
    route: Route,
    segments: UrlSegment[],
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let requestId = this.router.getCurrentNavigation()!.extractedUrl.queryParams['requestId'];
    if (requestId == null) return false;
    return this.isAuthenticated(requestId);
  }

  private isAuthenticated(requestId: string) {
    if (!this.credentialsService.isAuthenticated()) {
      return this.authenticate(requestId);
    }

    return this.authenticationService.getAuthenticationState().pipe(
      skipWhile((value) => value == null),
      switchMap((value) => {
        if (value) {
          return of(true);
        }
        return this.authenticate(requestId);
      }),
    );

    /*if (!this.isLoggedIn) {
      return this.authenticationService.getOrgApiKey(requestId).pipe(
        take(1),
        mergeMap((ogrKey) => {
          return this.authenticationService.loginByApiToken(ogrKey.key).pipe(
            map((val) => {
              return val != null;
            })
          );
        })
      );
    }

    return of(true);*/
  }

  private authenticate(requestId: string) {
    return this.authenticationService.getOrgApiKey(requestId).pipe(
      take(1),
      mergeMap((ogrKey) => {
        return this.authenticationService.loginByApiToken(ogrKey.key).pipe(
          map((val) => {
            return val != null;
          }),
        );
      }),
    );
  }
}
