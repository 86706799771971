import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { WithdrawalProductType } from '@generated/generatedEntities';

export const CalculatorIntegrationActions = createActionGroup({
  source: 'CalculatorIntegration',
  events: {
    'Reset Store': emptyProps(),
    CalculatorIntegrations: emptyProps(),
    'CalculatorIntegrations Success': props<{ data: unknown }>(),
    'CalculatorIntegrations Failure': props<{ error: unknown }>(),
    'Add Modules': props<{ modules: WithdrawalProductType[] }>(),
    'Remove Modules': props<{ modules: WithdrawalProductType[] }>(),
  },
});
