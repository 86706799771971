<div class="kv-login mat-typography bg-white flex-col" *transloco="let t">
  <div class="header-dimension flex-row flex-1">
    <h4
      style="align-self: center; margin-bottom: 0; margin-left: 1em"
      class="flex-grow-9"
      [innerHtml]="t('global.modals.contactDTM.title', { modul: t('prices.module.' + modul) })"
    ></h4>
    <button class="flex-grow-0" style="background-color: transparent" mat-icon-button [mat-dialog-close]="true">
      <mat-icon class="close-icon">close</mat-icon>
    </button>
  </div>

  <div class="login-container flex-row flex-1" style="margin-top: 20px; margin-left: 1.2em">
    <mat-card class="login-box">
      <div class="">
        <span style="margin-bottom: 25px" [innerHtml]="t('global.modals.contactDTM.text1')"></span>
      </div>
    </mat-card>
  </div>
  <mat-divider></mat-divider>
  <div mat-dialog-actions class="justify-end">
    <button
      mat-button
      type="button"
      class="btn btn-elegant-black btn-dtm"
      mat-dialog-close
      (click)="clear()"
      style="float: right; height: 37px"
    >
      <span class="glyphicon glyphicon-ban-circle"></span>&nbsp;
      <span data-translate="">{{ t("global.modals.contactDTM.footer-cancel.cancel") }}</span>
    </button>
  </div>
</div>
