<nav
  *transloco="let t"
  [ngClass]="makeDarker ? 'navbar makeDarker' : 'navbar navbar-default'"
  role="navigation"
  style="top: 0"
>
  <div class="container flex-row inherit">
    <div class="navbar-header space-start items-center">
      <!--      <button type="button" class="navbar-toggle" (click)="sidenav.toggle()">-->
      <!--        <span class="sr-only">Menu</span>-->
      <!--        <span class="icon-bar"></span>-->
      <!--        <span class="icon-bar"></span>-->
      <!--        <span class="icon-bar"></span>-->
      <!--      </button>-->
      <a class="logo logo-style" href="/" style="">
        <img class="logoObj" src="assets/images/logo_kennwerte.svg" alt="logo" />
        <span class="logo-title" style="">keeValue.ch</span>
      </a>
      <!--      <span class="logo-title" style="margin-left: 25%; color: white">Stand: 6.04.23 | 17:30</span>-->
      <img *ngIf="isInStateOperatingCostsForm()" class="logoObj2" src="assets/images/logo_pom.svg" alt="logo-pom" />
      <a *ngIf="showSmallBuilding()">
        <img
          class="logoObj3"
          [ngStyle]="{ display: showSmallBuilding() ? 'inline' : 'none' }"
          src="assets/images/background/other/Logo_AGV.svg"
          alt="logo-agv"
        />
      </a>
      <a *ngIf="showDamageCosts()">
        <img
          class="logoObj4"
          [ngStyle]="{ display: showDamageCosts() ? 'inline' : 'none' }"
          src="assets/images/background/other/Logo_GVB.svg"
          alt="logo-gvb"
        />
      </a>
      <a *ngIf="showInsuranceGvbs()">
        <svg
          id="Ebene_1"
          class="logo"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 100 116"
          style="background: transparent; height: 50px; margin-left: 200px; margin-top: 9px"
          xml:space="preserve"
        >
          <style type="text/css">
            .st0 {
              fill-rule: evenodd;
              clip-rule: evenodd;
              fill: #c51718;
            }

            .st1 {
              fill-rule: evenodd;
              clip-rule: evenodd;
              fill: #d47810;
            }

            .st2 {
              fill-rule: evenodd;
              clip-rule: evenodd;
              fill: #010202;
            }

            .st3 {
              fill-rule: evenodd;
              clip-rule: evenodd;
              fill: #feeb15;
            }
          </style>
          <path
            class="st0"
            d="M48.3,97c10.6,0.3,27-6.9,28.9-19.6c0.7-4.6,0.3-10.1-1.2-14.6c-1-3.6-4-8.4-6.2-11.3
	        c-3.6-4.1-7.3-8.1-10.4-12.7c-1.9-2.9-3.7-5.9-3.6-9.4c-1.7,1.5-2.7,3.6-3.7,5.7c-1.7,3.6-2.7,12.3-1.7,16.3c1.7-1.4,3.2-3.7,5-5.2
         	c0.5,7.3-3.9,14.1-3.8,21.4c-0.9,6.9,2.5,13.9,1.9,20.5c-1.1,5.1-4.6,6.6-6.3,7l-2.7-0.4C46.4,96.3,46.2,95.5,48.3,97"
          ></path>
          <path
            class="st1"
            d="M48.1,96.9c2.2-4.9-3.5-38.2-1.6-40.7c-2.3-4.1-2.3-12-0.5-16.1c-4.6,3.8-9.4,8.4-13.4,12.8
          	C19.4,67.5,15.9,92.6,48.5,96.9L48.1,96.9z"
          ></path>
          <path
            class="st2"
            d="M54.4,92.1c4.3-1.3,8.4-4,10.7-7.6c2.6-4.4,2.1-9.7,0.3-14.3c-0.3-1.4-1.1-2.4-1.6-3.7c-0.4-0.8-0.8-1.4-1.2-2
	          c-1.4-2.9-3.1-5.6-4-8.5c-3.7,10.6,4,22.1-1.8,32.3c-0.6,1.4-1.8,2.5-2.7,3.7C54.2,92,54.3,92.1,54.4,92.1"
          ></path>
          <path
            class="st2"
            d="M57,23.7c-9.2,4.1-16.4,10.6-24,17.2c0-1.1-1.4-7.2-2.4-9.1c-2.2,9.2-6.5,21.7-10.3,30.6
	          c0.5,0.2,9.5-10.2,18.7-20C47,33.9,55.9,26,57,23.7"
          ></path>
          <path
            class="st3"
            d="M53.6,81.5c-0.5-5-3.5-14.6,0.2-24.9c1.2-3.4,1.8-6.7,1.7-10.7c-1.7,1.3-3.4,3.6-5.1,5.2
          	c-14,14.1-11.5,20.5-5.9,33.2c0,0-12.5-10.5-10.2-17.3c-1.9,5.9-2.7,14.2,1.3,21.5C48.9,104.4,54.9,95.2,53.6,81.5"
          ></path>
        </svg>
      </a>
    </div>
    <ng-container>
      <div
        id="kennwerteNavbar"
        class="navbar-collapse flex-row flex-1 justify-end items-start"
        [ngSwitch]="isLoggedIn()"
        style="color: white; display: flex"
      >
        <ng-container *ngIf="showHeader()">
          <!-- [ngClass]="info.menuOpen ? 'active' : 'inActive'" -->
          <!-- [ngClass]="{active: url.path().includes('#home') || isInStateAccuracy() || isInStateAccuracyOperatingCosts() || isInStateSmallBuilding()}" -->
          <button
            id="info"
            class="mat-menu"
            data-value="info"
            mat-button
            [ngClass]="{
              active:
                url.path() === '/' ||
                isInStateAccuracy() ||
                isInStateAccuracyOperatingCosts() ||
                isInStateSmallBuilding(),
            }"
            #info="matMenuTrigger"
            panelclass="customize"
            [matMenuTriggerFor]="InfoMenu"
            [matMenuTriggerData]="{ trigger: 'info' }"
            (click)="openMenu($event)"
          >
            <mat-icon
              ><img class="mat-img" src="/assets/images/nav/informationen_bw.svg" width="18" height="18" />
            </mat-icon>
            <span class="hidden-sm hidden-md">{{ t("global.menu.products") }}</span>
            <b class="caret"></b>
          </button>
          <mat-menu
            class="navbar-item customize header-bolt-font"
            #InfoMenu="matMenu"
            yPosition="above"
            xPosition="before"
            style="top: auto"
          >
            <a class="ref-link" href="#home">
              <button mat-menu-item routerLink="/">
                <mat-icon><img class="Logo" src="assets/images/nav/logo_kennwerte_bw.svg" height="14" /></mat-icon>
                {{ t("products.start.quickNav.home") }}
              </button>
            </a>
            <a *ngIf="showBuildingCosts()" class="ref-link" href="#sectionId_baukosten">
              <button mat-menu-item routerLink="/">
                <mat-icon
                  ><img class="Logo" src="/assets/images/nav/baukosten_termine_cropped_bw.png" height="14"
                /></mat-icon>
                {{ t("products.start.quickNav.build") }}
              </button>
            </a>
            <a *ngIf="showRebuildCosts()" class="ref-link" href="#sectionId_umbaukosten">
              <button mat-menu-item routerLink="/">
                <mat-icon
                  ><img class="Logo" src="/assets/images/nav/versicherungswerte_cropped_bw.png" height="14"
                /></mat-icon>
                {{ t("products.start.quickNav.rebuild") }}
              </button>
            </a>
            <a *ngIf="showOperatingCosts()" class="ref-link" href="#sectionId_betriebskosten">
              <button mat-menu-item routerLink="/">
                <mat-icon><img class="Logo" src="/assets/images/nav/betriebskosten_bw.svg" height="14" /></mat-icon>
                {{ t("products.start.quickNav.bewk") }}
              </button>
            </a>

            <a *ngIf="showInsuranceValues()" class="ref-link" href="#sectionId_versicherungswerte">
              <button mat-menu-item routerLink="/">
                <mat-icon
                  ><img class="Logo" src="/assets/images/nav/versicherungswerte_cropped_bw.png" height="14"
                /></mat-icon>
                {{ t("products.start.quickNav.insuranceValue") }}
              </button>
            </a>

            <a *ngIf="showSmallBuilding()" class="ref-link" routerLink="/agv">
              <button mat-menu-item>
                <mat-icon
                  ><img class="Logo" src="/assets/images/nav/versicherungswerte_cropped_bw.png" height="14"
                /></mat-icon>
                {{ t("products.start.quickNav.smallBuilding") }}
                <span *ngIf="hasAnyBuildingCostsModule()">[AGV]</span>
              </button>
            </a>

            <a *ngIf="showSmallBuilding()" class="ref-link" href="agv#sectionId_versicherungswerte">
              <button mat-menu-item>
                <mat-icon
                  ><img class="Logo" src="/assets/images/nav/versicherungswerte_cropped_bw.png" height="14"
                /></mat-icon>
                {{ t("products.start.quickNav.insuranceValue") }}
                <span *ngIf="hasAnyBuildingCostsModule()"> [AGV] </span>
              </button>
            </a>

            <a class="ref-link" href="#sectionId_dienstleistungen">
              <button mat-menu-item routerLink="/">
                <mat-icon><img class="Logo" src="assets/images/nav/dienstleistungen_bw.svg" height="18" /></mat-icon>
                {{ t("products.start.quickNav.services") }}
              </button>
            </a>
            <a class="ref-link" href="#sectionId_about">
              <button mat-menu-item routerLink="/">
                <mat-icon><img class="Logo" src="/assets/images/nav/logo_kennwerte_bw.svg" height="18" /></mat-icon>
                {{ t("products.start.quickNav.about") }}
              </button>
            </a>
            <a class="ref-link" href="#sectionId_userfeedback">
              <button mat-menu-item routerLink="/">
                <mat-icon><img class="Logo" src="/assets/images/nav/userfeedback_bw.svg" height="18" /></mat-icon>
                {{ t("products.start.quickNav.userfeedback") }}
              </button>
            </a>
            <a class="ref-link" href="#sectionId_references">
              <button mat-menu-item routerLink="/">
                <mat-icon><img class="Logo" src="/assets/images/nav/userfeedback_bw.svg" height="18" /></mat-icon>
                {{ t("products.start.quickNav.references") }}
              </button>
            </a>
            <a class="ref-link" href="#sectionId_prices">
              <button mat-menu-item routerLink="/">
                <mat-icon
                  ><img class="Logo" src="/assets/images/nav/einmalige_berechnung_bw.svg" height="12"
                /></mat-icon>
                {{ t("products.start.quickNav.prices") }}
              </button>
            </a>
            <button mat-menu-item routerLink="info">
              <mat-icon
                ><img class="Logo" src="/assets/images/nav/funktionsweise_cropped_bw.svg" height="18"
              /></mat-icon>
              {{ t("products.start.quickNav.background-build") }}
            </button>
            <button mat-menu-item routerLink="info-operating-costs">
              <mat-icon
                ><img class="Logo" src="/assets/images/nav/funktionsweise_cropped_bw.svg" height="18"
              /></mat-icon>
              {{ t("products.start.quickNav.background-bewk") }}
            </button>
          </mat-menu>
          <button
            id="calculate"
            class="mat-menu"
            *kvHasModule="[
              'BUILDING_COSTS_AND_DATES',
              'REBUILD_COSTS',
              'OPERATING_COSTS',
              'LCC',
              'LCA',
              'EFFORT_PLANNER',
            ]"
            data-value="calculate"
            mat-button
            #calculate="matMenuTrigger"
            [matMenuTriggerFor]="calculateMenu"
            [matMenuTriggerData]="{ trigger: 'calculate' }"
            (click)="openMenu($event)"
            [ngClass]="{ active: isInStateOfAnyModule() }"
          >
            <mat-icon
              ><img class="mat-img" src="/assets/images/nav/baukosten_termine_cropped_bw.png" height="18" />
            </mat-icon>
            <span class="hidden-sm hidden-md">{{ t("global.menu.calculate") }}</span>
            <b class="caret"></b>
          </button>
          <mat-menu
            class="navbar-item customize header-bolt-font"
            #calculateMenu="matMenu"
            yPosition="above"
            xPosition="before"
          >
            <button
              mat-menu-item
              *kvHasModule="'BUILDING_COSTS_AND_DATES'"
              (click)="routeToCalculatorform('process-form/input')"
              [ngClass]="{ active: isInStateProcessFormBuildingCosts() }"
            >
              <mat-icon
                ><img class="Logo" src="assets/images/nav/baukosten_termine_cropped_bw.png" height="18" />
              </mat-icon>
              {{ t("global.menu.building-costs.input") }}
            </button>
            <button
              mat-menu-item
              *kvHasModule="'REBUILD_COSTS'"
              (click)="routeToCalculatorform('rebuild-form/input')"
              [ngClass]="{ active: isInStateRebuildForm() }"
            >
              <mat-icon><img class="Logo" src="assets/images/nav/rebuild_costs_bw.svg" height="18" /></mat-icon>
              {{ t("global.menu.rebuild-costs.input") }}
            </button>
            <button
              mat-menu-item
              *kvHasModule="'OPERATING_COSTS'"
              (click)="routeToCalculatorform('operational-form/input')"
              [ngClass]="{ active: isInStateOperatingCostsForm() }"
            >
              <mat-icon><img class="Logo" src="assets/images/nav/betriebskosten_bw.svg" height="18" /></mat-icon>
              {{ t("global.menu.operational-costs.input") }}
            </button>

            <button
              mat-menu-item
              *kvHasModule="[
                'BUILDING_COSTS_AND_DATES',
                'REBUILD_COSTS',
                'OPERATING_COSTS',
                'LCC',
                'LCA',
                'EFFORT_PLANNER',
              ]"
              (click)="routeToCalculatorform('planning-foundation-form/input')"
              [ngClass]="{ active: isInStatePlanningFoundation() }"
            >
              <mat-icon><img class="Logo" src="assets/images/nav/betriebskosten_bw.svg" height="18" /></mat-icon>
              {{ t("global.menu.planning-foundation-calculator.input") }}
            </button>

            <button
              mat-menu-item
              *kvHasModule="'LCC'"
              (click)="routeToCalculatorform('lcc-form/input')"
              [ngClass]="{ active: isInStateLccForm() }"
            >
              <mat-icon><img class="Logo" src="assets/images/nav/betriebskosten_bw.svg" height="18" /></mat-icon>
              {{ t("global.menu.lcc-costs.input") }}
            </button>
            <button
              mat-menu-item
              *kvHasModule="'LCA'"
              (click)="routeToCalculatorform('lca-form/input')"
              [ngClass]="{ active: isInStateLcaForm() }"
            >
              <mat-icon><img class="Logo" src="assets/images/nav/betriebskosten_bw.svg" height="18" /></mat-icon>
              {{ t("global.menu.lca-costs.input") }}
            </button>

            <!--'BUILDING_COSTS_AND_DATES'-->
            <button
              mat-menu-item
              *kvHasModule="['EFFORT_PLANNER']"
              (click)="routeToCalculatorform('effort-planner-form/input')"
              [ngClass]="{ active: isInStateEffortPlannerForm() }"
            >
              <mat-icon><img class="Logo" src="assets/images/nav/betriebskosten_bw.svg" height="18" /></mat-icon>
              {{ t("global.menu.effort-planner.input") }}
            </button>

            <button
              mat-menu-item
              *kvHasModule="['BIM_EVALUATION']"
              (click)="routeToCalculatorform('kv-to-bim/input')"
              [ngClass]="{ active: isInStateKv2BIM() }"
            >
              <mat-icon><img class="Logo" src="assets/images/nav/betriebskosten_bw.svg" height="18" /></mat-icon>
              kV2BIM
            </button>

            <button
              mat-menu-item
              routerLink="/building-costs/realestates"
              [ngClass]="{ active: isInStateBuildingCostsRealestates() }"
            >
              <mat-icon>
                <img
                  class="Logo"
                  src="assets/images/nav/Calculator/Gerechnete-Objekte-abholen-und-bearbeiten.svg"
                  height="18"
              /></mat-icon>
              {{ t("global.menu.realestates") }}
            </button>
            <button
              [ngClass]="{ active: isInStateBuildingCostsRealestatesReference() }"
              mat-menu-item
              *kvHasModule="'BUILDING_COSTS_AND_DATES'"
              routerLink="/building-costs/realestates-reference"
            >
              <mat-icon
                ><img
                  class="Logo"
                  src="assets/images/nav/Calculator/Referenzobjekte-anlegen-und-archivieren.svg"
                  height="18"
              /></mat-icon>
              {{ t("global.menu.realestates-reference") }}
            </button>
            <button mat-menu-item *kvHasAnyAuthority="'ROLE_PROTOTYPE'">Luucy öffnen</button>
          </mat-menu>
          <!--insurance dropdown-->
          <!-- [ngClass]="gvwerte.menuOpen ? 'active' : 'inActive'" -->
          <button
            id="gvwerte"
            *kvHasModule="[
              'BUILDING_INSURANCE_VALUES',
              'INSURANCE_VALUES_GVBS',
              'SMALL_BUILDING_GVBS',
              'SMALL_BUILDING_AGV',
            ]"
            class="mat-menu"
            data-value="gvwerte"
            mat-button
            #gvwerte="matMenuTrigger"
            [matMenuTriggerFor]="GVwerteberechnen"
            [matMenuTriggerData]="{ trigger: 'gvwerte' }"
            (click)="openMenu($event)"
            [ngClass]="{ active: isInStateInsuranceValues() }"
          >
            <mat-icon
              ><img class="mat-img" src="/assets/images/nav/versicherungswerte_cropped_bw.png" height="18" />
            </mat-icon>
            <span class="hidden-sm hidden-md">{{ t("products.start.quickNav.insurance") }}</span>
            <b class="caret"></b>
          </button>
          <mat-menu
            class="navbar-item customize header-bolt-font"
            #GVwerteberechnen="matMenu"
            yPosition="above"
            xPosition="before"
          >
            <button
              mat-menu-item
              *kvHasModule="'BUILDING_INSURANCE_VALUES'"
              (click)="routeToCalculatorform('insurance-form/input')"
              [ngClass]="{ active: isInStateProcessFormInsuranceValues(), buy: showInsuranceValuesFn() }"
            >
              <mat-icon
                ><img
                  class="Logo"
                  src="assets/images/nav/GV-Calculator/versicherungswerte_cropped_bw.svg"
                  height="18"
                />
              </mat-icon>
              {{ t("global.menu.insurance-values.input") }}
            </button>
            <button
              *ngIf="showInsuranceGvbs()"
              mat-menu-item
              appClearSessionStorageNavigate
              [targetRoute]="'gvbs/input'"
              [ngClass]="{ active: iSshowInsuranceGvbs() }"
            >
              <mat-icon
                ><img
                  class="Logo"
                  src="assets/images/nav/GV-Calculator/versicherungswerte_cropped_bw.svg"
                  height="18"
                />
              </mat-icon>
              {{ t("global.menu.insurance-values.input") }} GVBS
            </button>
            <button
              mat-menu-item
              appClearSessionStorageNavigate
              targetRoute="small-building-gvbs-form/input"
              *ngIf="showSmallBuildingGvbs()"
              [ngClass]="{ active: iSshowSmallBuildingGvbs() }"
            >
              <mat-icon
                ><img
                  class="Logo"
                  src="assets/images/nav/GV-Calculator/versicherungswerte_cropped_bw.svg"
                  height="18"
                />
              </mat-icon>
              {{ t("global.menu.small-building.input") }} GVBS
            </button>
            <button
              mat-menu-item
              *ngIf="!showInsuranceValues() && !showInsuranceGvbs()"
              (click)="contactDTmModal('building_insurance_values')"
            >
              <mat-icon
                ><img
                  class="Logo"
                  src="assets/images/nav/GV-Calculator/versicherungswerte_cropped_bw.svg"
                  height="18"
                />
              </mat-icon>
              {{ t("global.menu.insurance-values.get_licence") }}
            </button>
            <!-- small building form menu link -->
            <!-- *kvHasModule="'SMALL_BUILDING_AGV'"  -->
            <button
              mat-menu-item
              *ngIf="showSmallBuilding()"
              (click)="routeToCalculatorform('agv-small-building-form/input')"
              [ngClass]="{ active: isInStateSmallBuildingValues(), buy: showSmallBuildingFn() }"
            >
              <mat-icon
                ><img class="Logo" src="assets/images/nav/versicherungswerte_cropped_bw.png" height="18" />
              </mat-icon>
              {{ t("global.menu.small-building.input") }}
            </button>

            <!--  *kvHasModule="'BUILDING_INSURANCE_VALUES'"  -->
            <button
              mat-menu-item
              *ngIf="!showSmallBuilding() && licenseIssue.indexOf('SMALL_BUILDING_GVBS') == -1"
              (click)="contactDTmModal('small_building_agv')"
            >
              <mat-icon
                ><img
                  class="Logo"
                  src="assets/images/nav/GV-Calculator/Kleinbautenrechner-Lizenz-erwerben.svg"
                  height="18"
              /></mat-icon>
              {{ t("global.menu.small-building.get_licence") }}
            </button>

            <button
              mat-menu-item
              *kvHasModule="'DAMAGE_COSTS'"
              (click)="routeToCalculatorform('/damage-form/input')"
              [ngClass]="{ active: isInStateDamageCosts() }"
              ui-sref="damage-form.input({withdrawalProductType: 'damage_costs', realEstate: null, realEstateId: null})"
            >
              <mat-icon
                ><img class="Logo" src="assets/images/nav/versicherungswerte_cropped_bw.png" height="14" />
              </mat-icon>
              {{ t("global.menu.damage-costs.input") }}
            </button>

            <button
              *ngIf="isGvbsAdminOrHasOtherCalculator()"
              mat-menu-item
              routerLink="/insurance/realestates"
              [ngClass]="{ active: isInStateInsuranceValuesRealestates() }"
              ui-sref="insurance-values.realestates"
            >
              <mat-icon
                ><img
                  class="Logo"
                  src="assets/images/nav/GV-Calculator/Gerechnete-Objekte-abholen-und-bearbeiten.svg"
                  height="18"
                />
              </mat-icon>
              {{ t("global.menu.realestates") }}
            </button>
            <button
              mat-menu-item
              routerLink="/insurance/realestates-reference"
              [ngClass]="{ active: isInStateInsuranceValuesRealestatesReference() }"
              *ngIf="showInsuranceValues()"
              ui-sref="insurance-values.realestates-reference"
            >
              <mat-icon
                ><img
                  class="Logo"
                  src="assets/images/nav/GV-Calculator/Referenzobjekte-anlegen-und-archivieren.svg"
                  height="18"
                />
              </mat-icon>
              {{ t("global.menu.realestates-reference") }}
            </button>

            <!-- <button mat-menu-item>
            <mat-icon><img class="Logo" src="assets/images/nav/funktionsweise_cropped_bw.svg" height="14" /></mat-icon>
            {{ t('global.menu.insurance-values.get_licence') }}
          </button> -->
          </mat-menu>
          <!--Settings / register / Login-->
          <!-- [ngClass]="settings.menuOpen ? 'active' : 'inActive'" -->
          <button
            id="settings"
            class="mat-menu"
            data-value="settings"
            mat-button
            #settings="matMenuTrigger"
            [matMenuTriggerFor]="RegisterMenu"
            [matMenuTriggerData]="{ trigger: 'settings' }"
            (click)="openMenu($event)"
            [ngClass]="{
              active:
                url.path() === 'account' ||
                this.url.path().includes('settings') ||
                this.url.path().includes('password'),
            }"
          >
            <mat-icon><img class="mat-img" src="/assets/images/nav/zugang_bw.svg" height="18" /></mat-icon>
            <span class="hidden-sm hidden-md" data-translate="global.menu.account.main1" *ngSwitchCase="false"
              >{{ t("global.menu.account.main1") }}
            </span>
            <span class="hidden-sm hidden-md" data-translate="global.menu.account.main2" *ngSwitchCase="true">{{
              t("global.menu.account.main2")
            }}</span>
            <b class="caret"></b>
          </button>
          <mat-menu
            class="navbar-item customize header-bolt-font"
            #RegisterMenu="matMenu"
            yPosition="above"
            xPosition="before"
            style="top: auto"
          >
            <ng-container *ngIf="!hasAnyInsuranceModule()">
              <ng-container *ngSwitchCase="true">
                <button
                  *ngIf="isLoggedIn()"
                  mat-menu-item
                  routerLink="billing"
                  [ngClass]="{ active: url.path().includes('billing') }"
                >
                  <mat-icon><img class="mat-img" src="/assets/images/nav/Account/Lizenz.svg" height="18" /></mat-icon>
                  {{ t("global.menu.account.billing") }}
                </button>
              </ng-container>
            </ng-container>

            <ng-container ui-sref-active="active" *ngSwitchCase="true">
              <button
                *ngIf="isLoggedIn()"
                mat-menu-item
                routerLink="settings"
                (click)="routeToCalculatorform('settings')"
                [ngClass]="{ active: url.path().includes('settings') }"
              >
                <mat-icon><img class="mat-img" src="/assets/images/nav/Account/settings.svg" height="18" /></mat-icon>
                {{ t("global.menu.account.settings") }}
              </button>
            </ng-container>

            <ng-container ui-sref-active="active" *ngSwitchCase="true">
              <button
                *ngIf="isLoggedIn()"
                mat-menu-item
                routerLink="password"
                (click)="routeToCalculatorform('password')"
                [ngClass]="{ active: url.path().includes('password') }"
              >
                <mat-icon><img class="mat-img" src="/assets/images/nav/Account/password.svg" height="18" /></mat-icon>
                {{ t("global.menu.account.password") }}
              </button>
            </ng-container>
            <button ui-sref-active="active" *ngIf="!isLoggedIn()" (click)="openDialog()" mat-menu-item>
              <mat-icon><img class="mat-img" src="/assets/images/nav/Account/logout.svg" height="18" /></mat-icon>
              {{ t("global.menu.account.login") }}
            </button>
            <button
              *ngIf="!isLoggedIn()"
              mat-menu-item
              routerLink="register"
              [ngClass]="{ active: url.path().includes('register') }"
            >
              <mat-icon><img class="mat-img" src="/assets/images/nav/Account/register.svg" height="18" /></mat-icon>
              {{ t("global.menu.account.register") }}
            </button>
            <button *ngIf="isLoggedIn()" mat-menu-item (click)="logout()">
              <mat-icon><img class="mat-img" src="/assets/images/nav/Account/logout.svg" height="18" /></mat-icon>
              {{ t("global.menu.account.logout") }}
            </button>
          </mat-menu>
          <!--admin dropdown-->
          <button
            id="admin"
            *kvHasAnyAuthority="'ROLE_ADMIN'"
            class="mat-menu"
            data-value="admin"
            mat-button
            #admin="matMenuTrigger"
            [matMenuTriggerFor]="admininstrationMenu"
            [matMenuTriggerData]="{ trigger: 'admin' }"
            (click)="openMenu($event)"
            [ngClass]="{
              active:
                url.path().includes('admin') ||
                url.path().includes('organisation-management') ||
                url.path().includes('license-report') ||
                url.path().includes('status'),
            }"
          >
            <mat-icon><img class="mat-img" src="/assets/images/nav/top-nav/administration.svg" height="18" /></mat-icon>
            <span class="hidden-sm hidden-md">{{ t("global.menu.admin.main") }}</span>
            <b class="caret"></b>
          </button>
          <mat-menu
            class="navbar-item customize header-bolt-font"
            #admininstrationMenu="matMenu"
            yPosition="above"
            xPosition="before"
          >
            <button
              mat-menu-item
              routerLink="organisation-management"
              [ngClass]="{ active: url.path().includes('organisation-management') }"
            >
              <mat-icon>
                <img
                  class="Logo"
                  src="assets/images/nav/admin/Organizations-Administration.svg"
                  height="18"
                /> </mat-icon
              >&nbsp;Organisationen Verwaltung
            </button>
            <button
              mat-menu-item
              routerLink="license-report"
              [ngClass]="{ active: url.path().includes('license-report') }"
            >
              <mat-icon><img class="Logo" src="assets/images/nav/admin/Report Lizenzen.svg" height="18" /></mat-icon>
              Report Lizenzen
            </button>
            <button
              mat-menu-item
              routerLink="price-calculator"
              [ngClass]="{ active: url.path().includes('price-calculator') }"
            >
              <mat-icon><img class="Logo" src="assets/images/nav/admin/Preis-Berechner.svg" height="18" /></mat-icon>
              &nbsp;Preis-Berechner
            </button>
            <button mat-menu-item [ngClass]="{ active: url.path().includes('price-calculator') }">
              <mat-icon><img class="Logo" src="assets/images/nav/admin/Metriken.svg" height="18" /></mat-icon>&nbsp;{{
                t("global.menu.admin.metrics")
              }}
            </button>
            <button mat-menu-item routerLink="status" [ngClass]="{ active: url.path().includes('status') }">
              <mat-icon><img class="Logo" src="assets/images/nav/admin/Status.svg" height="18" /></mat-icon>&nbsp;{{
                t("global.menu.admin.health")
              }}
            </button>
            <button mat-menu-item [ngClass]="{ active: url.path().includes('configuration') }">
              <mat-icon><img class="Logo" src="assets/images/nav/admin/Konfiguration.svg" height="18" /></mat-icon>
              &nbsp;{{ t("global.menu.admin.configuration") }}
            </button>
            <button mat-menu-item routerLink="audit" [ngClass]="{ active: url.path().includes('audits') }">
              <mat-icon><img class="Logo" src="assets/images/nav/admin/Audits.svg" height="18" /></mat-icon>&nbsp;{{
                t("global.menu.admin.audits")
              }}
            </button>
            <button mat-menu-item [ngClass]="{ active: url.path().includes('logs') }">
              <mat-icon><img class="Logo" src="assets/images/nav/admin/Defin-Protokoll.svg" height="18" /></mat-icon>
              &nbsp;{{ t("global.menu.admin.logs") }}
            </button>
            <button mat-menu-item>
              <mat-icon><img class="Logo" src="assets/images/nav/admin/API.svg" height="18" /></mat-icon>&nbsp;{{
                t("global.menu.admin.apidocs")
              }}
            </button>
          </mat-menu>
        </ng-container>
        <!--Language dropdown-->
        <button
          id="language"
          class="mat-menu"
          data-value="language"
          mat-button
          #language="matMenuTrigger"
          [matMenuTriggerFor]="LanguageMenu"
          [matMenuTriggerData]="{ trigger: 'language' }"
          (click)="openMenu($event)"
        >
          <mat-icon><img class="mat-img mat-flag" src="/assets/images/nav/flag-solid.svg" height="14" /></mat-icon>
          <span class="hidden-sm hidden-md">{{ "global.menu.language" | transloco }}</span>
          <b class="caret"></b>
        </button>
        <mat-menu
          class="navbar-item customize header-bolt-font"
          #LanguageMenu="matMenu"
          yPosition="above"
          xPosition="before"
        >
          <button mat-menu-item (click)="languageChange('de-CH')" [ngClass]="{ active: activeLang === 'de-CH' }">
            <span class="glyphicon glyphicon-flag"></span>Deutsch
          </button>
          <button mat-menu-item (click)="languageChange('fr-CH')" [ngClass]="{ active: activeLang === 'fr-CH' }">
            <span class="glyphicon glyphicon-flag"></span>Français
          </button>
        </mat-menu>
      </div>
    </ng-container>
  </div>
</nav>
