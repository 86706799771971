import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BimViewerService {
  private glbFile: any = null;
  private checkReport: any;
  private data: any;

  public storeLargeFileData(data: any) {
    // console.error('storeLargeFileData');
    this.glbFile = data;
  }

  public getLargeFileData() {
    // console.warn('getLargeFileData');
    return this.glbFile;
  }
}
