import { Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RebuildTemplateService } from '../rebuild-template/rebuild-template.service';

@Injectable({
  providedIn: 'root',
})
export class RebuildGeometryService {
  private _rebuildTemplateService = inject(RebuildTemplateService);
  service$: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null); // as ngOnChanges don't get called on second change
  service: any = {
    isNone: false,
    isAnnex: false,
    isAdditionStory: false,
    // refresh: this.refresh
  };

  refresh(selectedTemplates: any) {
    this.service.isNone = this._rebuildTemplateService.isNone(selectedTemplates);
    this.service.isAnnex = this._rebuildTemplateService.isAnnex(selectedTemplates);
    this.service.isAdditionStory = this._rebuildTemplateService.isAdditionStory(selectedTemplates);
    this.service$.next(this.service);
  }
}
