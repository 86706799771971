import { Component, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Logger } from '@app/@shared';
import { AuthenticationService } from '@app/auth';
import { environment } from '@env/environment';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { finalize } from 'rxjs';

const log = new Logger('Login');
@UntilDestroy()
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: ['prices', 'global', 'register', 'settings', 'model_realEstate', 'reset', 'password'],
    },
  ],
})
export class ResetPasswordComponent implements OnInit {
  private formBuilder = inject(UntypedFormBuilder);
  private authenticationService = inject(AuthenticationService);
  private route = inject(ActivatedRoute);
  title: string = 'Passwort zurücksetzen'; //TODO i18n
  resetForm!: UntypedFormGroup;
  version: string | null = environment.version;
  error: string | undefined;
  isLoading = false;

  success!: string | null;
  errorEmailNotExists!: string | null;
  requestId: string;

  constructor() {
    this.requestId = this.route.snapshot.queryParams['key'] as string;
    console.log('this.requestId', this.requestId);
    this.resetFormData();
  }

  ngOnInit(): void {}

  resetFormData() {
    this.resetForm = this.formBuilder.group({
      mail: ['', [Validators.required, Validators.email, Validators.minLength(5), Validators.maxLength(100)]],
    });
  }

  resetPassword() {
    this.isLoading = true;
    console.log('form data', this.resetForm.value);
    const email = this.resetForm.controls['mail'].value;
    const pwd$ = this.authenticationService.resetformDetails(email.toString());
    pwd$
      .pipe(
        finalize(() => {
          this.resetForm.markAsPristine();
          this.isLoading = false;
        }),
        untilDestroyed(this),
      )
      .subscribe({
        next: (response) => {
          if (response) {
            this.success = 'OK';
            this.errorEmailNotExists = null;
          }
          console.log('reset data');
        },
        error: (e) => {
          console.error(e);
          this.success = null;
          log.debug(`password error: ${e}`);
          if (e.status === 400 && e.error === 'email address not registered') {
            this.errorEmailNotExists = 'ERROR';
          } else {
            this.error = 'ERROR';
          }
          this.error = e;
        },
      });
  }
}
