import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  private scrollY$ = new Subject<number>();
  getScrollY(): Observable<number> {
    return this.scrollY$.asObservable();
  }
  setScrollY(axisY: number) {
    this.scrollY$.next(axisY);
  }
}
