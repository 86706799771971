import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { LicenseComponent } from './license/license.component';

const routes: Routes = [
  {
    path: 'license',
    component: LicenseComponent,
    data: { title: '' },
  },
];

export const INSUFFICIENT_ROUTES = {
  LICENSE: 'insufficient-rights/license',
};

@NgModule({
  imports: [RouterModule.forChild(routes), TranslocoModule],
  exports: [RouterModule],
})
export class InsufficientRightsRoutingModule {}
