<div *transloco="let t">
  <button
    type="button"
    class="btn btn-elegant-black"
    room-height
    translate="process-form.showRoomHeight"
    style="margin-bottom: 20px"
    (click)="setValuesOnClickEvent()"
  >
    {{ t("processform.showRoomHeight") }}
  </button>
</div>
