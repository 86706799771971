import { Component, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from '@app/auth';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';

@Component({
  selector: 'app-contact-dtm-dialog',
  templateUrl: './contact-dtm-dialog.component.html',
  styleUrls: ['./contact-dtm-dialog.component.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: [
        'prices',
        'global',
        'register',
        'settings',
        'process_form',
        'model_geometry',
        'model_realEstate',
        'realestates',
        'model_complexity',
        'model_quality',
      ],
    },
  ],
})
export class ContactDtmDialogComponent implements OnInit {
  private dialogRef = inject<MatDialogRef<ContactDtmDialogComponent>>(MatDialogRef<ContactDtmDialogComponent>);
  public modulObj = inject<any>(MAT_DIALOG_DATA);
  modul: any;
  constructor() {
    this.modul = this.modulObj.modul.modul ? this.modulObj.modul.modul.toUpperCase() : '';
  }

  ngOnInit(): void {}

  clear() {
    this.dialogRef.close({
      response: 'OK',
    });
  }
}
