import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, retry, switchMap, takeUntil } from 'rxjs/operators';
import { RealEstateActions } from './real-estate.actions';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { environment } from '@env/environment';
import { debounceTime, distinctUntilChanged, from, of } from 'rxjs';
import {
  AdditionEntryDTO,
  EnergyContainer,
  OutRealEstateDTO,
  RealEstateDTO,
  RealEstateUsageEmbeddable,
  RebuildEstimationOutputDTO,
  WithdrawalProductType,
  WithdrawDTO,
} from '@generated/generatedEntities';
import { CALCULATION_STATE, realEstatesFeature } from '@app/core/services/store/real-estate.state';
import _ from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LcaBuildingElementPresetDTO } from '@generated/extended';
import { concatLatestFrom } from '@ngrx/operators';
import { SharedControllerFnService } from '@app/core/services/shared/shared-controller-fn.service';

@UntilDestroy()
@Injectable()
export class RealEstateEffects {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly store = inject(Store);
  private readonly actions$: Actions = inject(Actions);
  private sharedControllerFnService = inject(SharedControllerFnService);

  loadRealEstateDtos$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RealEstateActions.getEmptyRealEstate),
      concatLatestFrom(() => this.store.select(realEstatesFeature.selectInitialRealEstate)),
      concatMap(([request, filters]) =>
        this.httpClient.get(`${environment.serverUrl}/api/rebuild_estate/empty`).pipe(
          untilDestroyed(this),
          map((realestate: any) => {
            realestate.metaData.withdrawalProductType = request.wpt;
            realestate.metaData.tasks = request.tasks.length === 0 ? [request.wpt] : request.tasks;
            this.postProcessEmptyRealEstateObject(realestate, request.wpt);
            return RealEstateActions.loadRealEstateSuccess(realestate);
          }),
          catchError((error) => of(RealEstateActions.loadRealEstateFailure(error))),
        ),
      ),
    );
  });

  presetLCA$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        RealEstateActions.estimateLCAEnergyNeeds,
        RealEstateActions.estimateLCAEnergyNeedsAndPerformance,
        RealEstateActions.presetLCA,
      ),
      concatLatestFrom(() => this.store.select(realEstatesFeature.selectRealEstateWithStatus)),
      distinctUntilChanged((previous, current) => JSON.stringify(previous) === JSON.stringify(current)),
      takeUntil(this.actions$.pipe(ofType(RealEstateActions.estimateLCACosts))),
      switchMap(([request, filters]) => {
        // console.warn(
        //   `presetLCA$ - filter status: ${JSON.stringify(filters.status)} filters realEstate: ${JSON.stringify(filters.realEstate)}`,
        // );
        if (
          filters.status === CALCULATION_STATE.CALCULATING ||
          filters.status === CALCULATION_STATE.FINAL_RESULT_LOADED
        ) {
          return of(RealEstateActions.noopDueToStatus());
        }

        //we don't want to send the current energy object to the backend - as we will make calculations based on the values there.
        const requestDTO = _.cloneDeep(filters.realEstate);
        const precondition1 =
          requestDTO.quality.facadeTypes.length > 0 &&
          requestDTO.quality.facadeTypes.filter((ft) => ft.facadeType != null).length > 0;

        const precondition2 = requestDTO.quality.minergieStandard != null;
        const precondition3 = requestDTO.usages.filter((u) => u.type != null).length > 0;
        this.sharedControllerFnService.cleanUsages(requestDTO.usages);
        if (precondition1 && precondition2 && precondition3) {
          return this.httpClient
            .post<LcaBuildingElementPresetDTO>(`${environment.lcaPresetServiceUrl}/lca/preset/facade`, requestDTO)
            .pipe(
              untilDestroyed(this),
              takeUntil(this.actions$.pipe(ofType(RealEstateActions.estimateLCACosts))),
              map((data) => {
                // console.warn(data);
                return RealEstateActions.presetLCASuccess(data);
              }),
              catchError((error) => of(RealEstateActions.presetLCAError(error))),
            );
        } else {
          return of(RealEstateActions.presetLCANoop({ preconditions: [precondition1, precondition2, precondition3] }));
        }
      }),
    );
  });
  presetLCAFu$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RealEstateActions.presetLCAFunctionalUnits),
      concatLatestFrom(() => this.store.select(realEstatesFeature.selectRealEstateWithStatus)),
      distinctUntilChanged((previous, current) => JSON.stringify(previous) === JSON.stringify(current)),
      switchMap(([request, filters]) => {
        // console.warn(
        //   `presetLCA$ - filter status: ${JSON.stringify(filters.status)} filters realEstate: ${JSON.stringify(filters.realEstate)}`,
        // );
        if (
          filters.status === CALCULATION_STATE.CALCULATING ||
          filters.status === CALCULATION_STATE.FINAL_RESULT_LOADED
        ) {
          return of(RealEstateActions.noopDueToStatus());
        }
        //we don't want to send the current energy object to the backend - as we will make calculations based on the values there.
        const requestDTO = _.cloneDeep(filters.realEstate);
        const precondition1 = requestDTO.geometry.energyReferenceArea >= 0;
        const precondition2 = requestDTO.usages.filter((u) => u.type != null).length > 0;
        this.sharedControllerFnService.cleanUsages(requestDTO.usages);

        if (precondition1 && precondition2) {
          return this.httpClient
            .post<RealEstateUsageEmbeddable[]>(`${environment.lcaPresetServiceUrl}/lca/preset/fu`, requestDTO)
            .pipe(
              untilDestroyed(this),
              takeUntil(this.actions$.pipe(ofType(RealEstateActions.estimateLCACosts))),
              map((data) => {
                console.warn('presetLCAFunctionalUnits', data);
                return RealEstateActions.presetLCAFunctionalUnitsSuccess({ data: data });
              }),
              catchError((error) => of(RealEstateActions.presetLCAFunctionalUnitsError(error))),
            );
        } else {
          return of(RealEstateActions.presetLCAFunctionalUnitsNoop({ preconditions: [precondition1, precondition2] }));
        }
      }),
    );
  });
  presetLCAPv$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RealEstateActions.presetLCAFunctionalUnits, RealEstateActions.presetLCAPv),
      concatLatestFrom(() => this.store.select(realEstatesFeature.selectRealEstateWithStatus)),
      distinctUntilChanged((previous, current) => JSON.stringify(previous) === JSON.stringify(current)),
      switchMap(([request, filters]) => {
        // console.warn(
        //   `presetLCA$ - filter status: ${JSON.stringify(filters.status)} filters realEstate: ${JSON.stringify(filters.realEstate)}`,
        // );
        if (
          filters.status === CALCULATION_STATE.CALCULATING ||
          filters.status === CALCULATION_STATE.FINAL_RESULT_LOADED
        ) {
          return of(RealEstateActions.noopDueToStatus());
        }
        //we don't want to send the current energy object to the backend - as we will make calculations based on the values there.
        const requestDTO = _.cloneDeep(filters.realEstate);
        const precondition1 = requestDTO.geometry.energyReferenceArea >= 0;
        const precondition2 = requestDTO.usages.filter((u) => u.type != null).length > 0;
        this.sharedControllerFnService.cleanUsages(requestDTO.usages);

        if (precondition1 && precondition2) {
          return this.httpClient
            .post<{ pvRoof: number }>(`${environment.lcaPresetServiceUrl}/lca/preset/pv`, requestDTO)
            .pipe(
              untilDestroyed(this),
              takeUntil(this.actions$.pipe(ofType(RealEstateActions.estimateLCACosts))),

              map((data) => {
                console.warn('presetLCAPvSuccess', data);
                return RealEstateActions.presetLCAPvSuccess(data);
              }),
              catchError((error) => of(RealEstateActions.presetLCAPvError(error))),
            );
        } else {
          return of(RealEstateActions.presetLCAFunctionalUnitsNoop({ preconditions: [precondition1, precondition2] }));
        }
      }),
    );
  });

  // presetLCAuValues$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(RealEstateActions.presetUValueLCA),
  //     concatLatestFrom(() => this.store.select(realEstatesFeature.selectRealEstateWithStatus)),
  //     distinctUntilChanged((previous, current) => JSON.stringify(previous) === JSON.stringify(current)),
  //     switchMap(([request, filters]) => {
  //       // console.warn(
  //       //   `presetLCA$ - filter status: ${JSON.stringify(filters.status)} filters realEstate: ${JSON.stringify(filters.realEstate)}`,
  //       // );
  //       if (
  //         filters.status === CALCULATION_STATE.CALCULATING ||
  //         filters.status === CALCULATION_STATE.FINAL_RESULT_LOADED
  //       ) {
  //         return of(RealEstateActions.noopDueToStatus());
  //       }
  //       //we don't want to send the current energy object to the backend - as we will make calculations based on the values there.
  //       const requestDTO = _.cloneDeep(filters.realEstate);
  //       const precondition1 = requestDTO.geometry.energyReferenceArea >= 0;
  //       const precondition2 = requestDTO.usages.filter((u) => u.type != null).length > 0;
  //       this.sharedControllerFnService.cleanUsages(requestDTO.usages);
  //
  //       if (precondition1 && precondition2) {
  //         return this.httpClient
  //           .post<LcaBuildingElementPresetDTO>(`${environment.lcaPresetServiceUrl}/lca/preset/u-value`, requestDTO)
  //           .pipe(
  //             untilDestroyed(this),
  //             takeUntil(
  //               this.actions$.pipe(
  //                 ofType(
  //                   RealEstateActions.estimateLCACosts,
  //                   RealEstateActions.estimateLCACostsSuccess,
  //                   RealEstateActions.estimateLCACostsError,
  //                 ),
  //               ),
  //             ),
  //             map((data) => {
  //               console.warn('presetUValueLCASuccess: ', data);
  //               return RealEstateActions.presetUValueLCASuccess(data);
  //             }),
  //             catchError((error) => of(RealEstateActions.presetUValueLCAError(error))),
  //           );
  //       } else {
  //         return of(RealEstateActions.presetLCAFunctionalUnitsNoop({ preconditions: [precondition1, precondition2] }));
  //       }
  //     }),
  //   );
  // });

  presetWpLCA$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RealEstateActions.presetLCAHPPower, RealEstateActions.estimateLCAEnergyNeedsAndPerformanceSuccess),
      concatLatestFrom(() => this.store.select(realEstatesFeature.selectRealEstateWithStatus)),
      distinctUntilChanged((previous, current) => JSON.stringify(previous) === JSON.stringify(current)),
      switchMap(([request, filters]) => {
        //we don't want to send the current energy object to the backend - as we will make calculations based on the values there.
        if (
          filters.status === CALCULATION_STATE.CALCULATING ||
          filters.status === CALCULATION_STATE.FINAL_RESULT_LOADED
        ) {
          return of(RealEstateActions.noopDueToStatus());
        }
        const requestDTO = _.cloneDeep(filters.realEstate);

        const precondition1 =
          requestDTO.quality.heatingContainers.filter(
            (hc) =>
              hc.heatingType === 'HEAT_PUMP_GEOTHERMAL' ||
              hc.heatingType === 'HEAT_PUMP_AIR' ||
              hc.heatingType === 'HEAT_PUMP_GROUND_COLLECTOR' ||
              hc.heatingType === 'HEAT_PUMP_GROUND_WATER',
          ).length === 1;

        const precondition2 =
          (requestDTO.energy.current.annualHeatingNeedsUserInput != null &&
            requestDTO.energy.current.annualHeatingNeedsUserInput >= 0) ||
          (requestDTO.energy.current.annualHeatingNeedsPlaceholder != null &&
            requestDTO.energy.current.annualHeatingNeedsPlaceholder >= 0);
        const precondition3 =
          (requestDTO.energy.current.annualDhwNeedsUserInput != null &&
            requestDTO.energy.current.annualDhwNeedsUserInput >= 0) ||
          (requestDTO.energy.current.annualDhwNeedsPlaceholder != null &&
            requestDTO.energy.current.annualDhwNeedsPlaceholder >= 0);

        this.sharedControllerFnService.cleanUsages(requestDTO.usages);

        if (precondition1 && precondition2 && precondition3) {
          return this.httpClient.post<number[]>(`${environment.serverUrl}/api/withdrawal/preset/hp`, requestDTO).pipe(
            untilDestroyed(this),
            takeUntil(this.actions$.pipe(ofType(RealEstateActions.estimateLCACosts))),
            map((data) => {
              // console.warn(data);
              return RealEstateActions.presetLCAHPPowerSuccess({ data });
            }),
            catchError((error) => of(RealEstateActions.presetLCAHPPowerError(error))),
          );
        } else {
          return of(
            RealEstateActions.presetLCAHPPowerNoop({ preconditions: [precondition1, precondition2, precondition3] }),
          );
        }
      }),
    );
  });

  estimateLCA$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RealEstateActions.estimateLCACosts),
      concatLatestFrom(() => this.store.select(realEstatesFeature.selectRealEstateProgress)),
      switchMap(([request, filters]) => {
        var requestDTO = _.cloneDeep(filters) as RealEstateDTO;
        //TODO this should be somewhere else - but for now we do it here. If we keep those values in there we will print them - maybe it also makes sense to clean them in the backend?
        requestDTO.quality.exteriorWallsCladdingAboveTerrain =
          requestDTO.quality.exteriorWallsCladdingAboveTerrain.filter((x) => x.area != null);
        requestDTO.quality.windowsAboveTerrain = requestDTO.quality.windowsAboveTerrain.filter((x) => x.area != null);
        return this.httpClient
          .post<WithdrawDTO>(`${environment.serverUrl}/api/withdrawal/energy/estimate`, requestDTO)
          .pipe(
            untilDestroyed(this),
            map((withdrawDTO: any) => RealEstateActions.estimateLCACostsSuccess(withdrawDTO)),
            catchError((error, caught) => of(RealEstateActions.estimateLCACostsError({ error: caught }))),
          );
      }),
      catchError((error, caught) => of(RealEstateActions.estimateLCACostsError({ error: caught }))),
    );
  });

  presetEnergyNeeds$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RealEstateActions.estimateLCAEnergyNeeds, RealEstateActions.estimateLCAEnergyNeedsAndPerformance),
      //we don't want to blast the preset API with calls - so we add a debounce to at least channel  few updates together - this happends mainly when loading a object - or rapid updates on the form.
      debounceTime(200),
      concatLatestFrom(() => this.store.select(realEstatesFeature.selectRealEstateWithStatus)),
      distinctUntilChanged((previous, current) => JSON.stringify(previous) === JSON.stringify(current)),
      switchMap(([request, filters]) => {
        if (
          filters.status === CALCULATION_STATE.CALCULATING ||
          filters.status === CALCULATION_STATE.FINAL_RESULT_LOADED
        ) {
          return of(RealEstateActions.noopDueToStatus());
        }

        //we don't want to send the current energy object to the backend - as we will make calculations based on the values there.
        const requestDTO = _.cloneDeep(filters.realEstate);
        // console.log('presetLCA$ - filters.status: ', filters.status);
        // console.log('presetLCA$ - filters.realEstate: ', filters.realEstate);
        // requestDTO.agv = undefined;
        requestDTO.energy.current = {} as EnergyContainer;
        if (requestDTO.metaData.tasks.includes('REBUILD_COSTS')) {
          requestDTO.energy.target = {} as EnergyContainer;
        }
        const precondition1 =
          requestDTO.quality.exteriorWallsCladdingAboveTerrain.filter(
            (w) => w.area != null && (w.uvalue != null || w.uvaluePlaceholder != null),
          ).length > 0;

        var precondition2 = true;
        if (requestDTO.quality.roofTypes.includes('FLATROOF')) {
          precondition2 =
            requestDTO.quality.flatRoofsAboveTerrain.filter(
              (w) => w.area != null && (w.uvalue != null || w.uvaluePlaceholder != null),
            ).length > 0;
        }
        var precondition3 = true;
        if (requestDTO.quality.roofTypes.includes('INCLINEDROOF')) {
          precondition3 =
            requestDTO.quality.inclinedRoofsAboveTerrain.filter(
              (w) => w.area != null && (w.uvalue != null || w.uvaluePlaceholder != null),
            ).length > 0;
        }
        const precondition4 =
          requestDTO.quality.groundFloorSlabs.filter(
            (w) => w.area != null && (w.uvalue != null || w.uvaluePlaceholder != null),
          ).length > 0;

        this.sharedControllerFnService.cleanUsages(requestDTO.usages);

        if (
          requestDTO.geometry.elevator != null &&
          requestDTO.geometry.elevator.vertical == null &&
          requestDTO.geometry.elevator.placeholder != null
        ) {
          requestDTO.geometry.elevator.vertical = requestDTO.geometry.elevator.placeholder;
        }
        // console.log('presetLCA$ - requestDTO.usages: ', requestDTO.usages);
        if (precondition1 && precondition2 && precondition3 && precondition4) {
          return this.httpClient
            .post<RebuildEstimationOutputDTO>(`${environment.serverUrl}/api/withdrawal/energy/needs`, requestDTO)
            .pipe(
              untilDestroyed(this),
              takeUntil(this.actions$.pipe(ofType(RealEstateActions.estimateLCACosts))),

              map((energyNeeds: RebuildEstimationOutputDTO) => {
                return RealEstateActions.estimateLCAEnergyNeedsSuccess(energyNeeds);
              }),
              catchError((error) => {
                console.warn('/api/withdrawal/energy/needs error: ', error);
                return of(RealEstateActions.estimateLCAEnergyNeedsFailure(error));
              }),
            );
        } else {
          return of(
            RealEstateActions.estimateLCAEnergyNeedsNoop({
              preconditions: [precondition1, precondition2, precondition3, precondition4],
            }),
          );
        }
      }),
    );
  });

  estimateEnergyPerformance$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        RealEstateActions.estimateLCAEnergyPerformance,
        RealEstateActions.estimateLCAEnergyNeedsAndPerformance,
        RealEstateActions.estimateLCAEnergyNeedsSuccess,
      ),
      debounceTime(200),
      concatLatestFrom(() => this.store.select(realEstatesFeature.selectRealEstateWithStatus)),
      distinctUntilChanged((previous, current) => JSON.stringify(previous) === JSON.stringify(current)),
      switchMap(([request, filters]) => {
        if (
          filters.status === CALCULATION_STATE.CALCULATING ||
          filters.status === CALCULATION_STATE.FINAL_RESULT_LOADED
        ) {
          return of(RealEstateActions.noopDueToStatus());
        }

        const requestDTO = _.cloneDeep(filters.realEstate);
        requestDTO.energy.current = {} as EnergyContainer;

        const precondition1 =
          requestDTO.quality.exteriorWallsCladdingAboveTerrain.filter(
            (w) => w.area != null && (w.uvalue != null || w.uvaluePlaceholder != null),
          ).length > 0;

        var precondition2 = true;
        if (requestDTO.quality.roofTypes.includes('FLATROOF')) {
          precondition2 =
            requestDTO.quality.flatRoofsAboveTerrain.filter(
              (w) => w.area != null && (w.uvalue != null || w.uvaluePlaceholder != null),
            ).length > 0;
        }
        var precondition3 = true;
        if (requestDTO.quality.roofTypes.includes('INCLINEDROOF')) {
          precondition3 =
            requestDTO.quality.inclinedRoofsAboveTerrain.filter(
              (w) => w.area != null && (w.uvalue != null || w.uvaluePlaceholder != null),
            ).length > 0;
        }
        const precondition4 =
          requestDTO.quality.groundFloorSlabs.filter(
            (w) => w.area != null && (w.uvalue != null || w.uvaluePlaceholder != null),
          ).length > 0;

        if (
          requestDTO.geometry.elevator != null &&
          requestDTO.geometry.elevator.vertical == null &&
          requestDTO.geometry.elevator.placeholder != null
        ) {
          if (requestDTO.geometry.elevator['verticalDecision'] == 'no') {
            requestDTO.geometry.elevator.vertical = 0;
          } else {
            requestDTO.geometry.elevator.vertical = requestDTO.geometry.elevator.placeholder;
          }
        }

        this.sharedControllerFnService.cleanUsages(requestDTO.usages);
        if (precondition1 && precondition2 && precondition3 && precondition4) {
          return this.httpClient
            .post<RebuildEstimationOutputDTO>(`${environment.serverUrl}/api/withdrawal/energy/performance`, requestDTO)
            .pipe(
              untilDestroyed(this),
              takeUntil(this.actions$.pipe(ofType(RealEstateActions.estimateLCACosts))),
              map((data) => {
                return RealEstateActions.estimateLCAEnergyNeedsAndPerformanceSuccess({ data });
              }),
              catchError((error) => of(RealEstateActions.estimateLCAEnergyPerformanceFailure(error))),
            );
        } else {
          return of(
            RealEstateActions.estimateLCAEnergyNeedsAndPerformanceNoop({
              preconditions: [precondition1, precondition2, precondition3, precondition4],
            }),
          );
        }
      }),
    );
  });

  estimateGreyEnergy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RealEstateActions.estimateLCAGreyEnergy),
      concatLatestFrom(() => this.store.select(realEstatesFeature.selectRealEstateProgress)),
      switchMap(([request, filters]) =>
        this.httpClient.post(`${environment.serverUrl}/api/withdrawal/energy/grey`, filters).pipe(
          untilDestroyed(this),
          map((greyEnergyResult: any) => {
            return RealEstateActions.estimateLCAGreyEnergySuccess(greyEnergyResult);
          }),
          catchError((error) => of(RealEstateActions.estimateLCAGreyEnergyFailure(error))),
        ),
      ),
    );
  });

  plotCRREMCO2$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RealEstateActions.estimateLCACostsSuccess),
      concatLatestFrom(() => this.store.select(realEstatesFeature.selectWithdrawDTO)),
      concatMap(([request, filters]) => {
        var payload = {
          ...filters.outputDTO.lcaOutputPlot.crremPlotCO2Dto,
          width: 500,
          height: 300,
        };

        const headers = new HttpHeaders()
          .set('content-type', 'application/json')
          // .set('Access-Control-Allow-Origin', '*')
          .append('Accept', 'image/svg+xml');
        return this.httpClient
          .post(`${environment.kvPlotService}/crrem-co2`, payload, { headers, responseType: 'blob' })
          .pipe(
            untilDestroyed(this),
            retry({ count: 3, delay: 1500 }),
            switchMap((image: Blob) => {
              const blobToBase64 = (blob: Blob): Promise<string> => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                return new Promise((resolve) => {
                  reader.onloadend = () => {
                    resolve(reader.result as string);
                  };
                });
              };
              return from(blobToBase64(image));
            }),
            map((base64: string) => RealEstateActions.getLCAPlotCRREMCO2Success({ base64 })),
            catchError((error) => of(RealEstateActions.getLCAPlotCRREMCO2Error(error))),
          );
      }),
    );
  });

  plotNonRenewableEnergy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RealEstateActions.estimateLCACostsSuccess),
      // ofType(RealEstateActions.getLCAPlotCRREMNonRenewableEnergy),
      concatLatestFrom(() => this.store.select(realEstatesFeature.selectWithdrawDTO)),
      concatMap(([request, filters]) => {
        var payload = {
          ...filters.outputDTO.lcaOutputPlot.crremPlotPNRDto,
          width: 500,
          height: 300,
        };
        const headers = new HttpHeaders()
          .set('content-type', 'application/json')
          // .set('Access-Control-Allow-Origin', '*')
          .append('Accept', 'image/svg+xml');
        return this.httpClient
          .post(`${environment.kvPlotService}/crrem-energy`, payload, { headers, responseType: 'blob' })
          .pipe(
            untilDestroyed(this),
            retry({ count: 3, delay: 1500 }),
            switchMap((image: Blob) => {
              const blobToBase64 = (blob: Blob): Promise<string> => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                return new Promise((resolve) => {
                  reader.onloadend = () => {
                    resolve(reader.result as string);
                  };
                });
              };
              return from(blobToBase64(image));
            }),
            map((base64: string) => RealEstateActions.getLCAPlotCRREMNonRenewableEnergySuccess({ base64 })),
            catchError((error) => of(RealEstateActions.getLCAPlotCRREMNonRenewableEnergyError(error))),
          );
      }),
    );
  });

  plotYearlyEnergy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RealEstateActions.estimateLCACostsSuccess),
      // ofType(RealEstateActions.getLCAPlotCRREMNonRenewableEnergy),
      concatLatestFrom(() => this.store.select(realEstatesFeature.selectWithdrawDTO)),
      concatMap(([request, filters]) => {
        const deepCopyEnergyPlotDTO = _.cloneDeep(filters.outputDTO.lcaOutputPlot.energyPlotDTO);
        deepCopyEnergyPlotDTO.forEach((element) => {
          element.labelByYear = '';
          element.width = 150;
        });
        var payload = deepCopyEnergyPlotDTO;
        // width: 500,
        // height: 300,

        const headers = new HttpHeaders()
          .set('content-type', 'application/json')
          // .set('Access-Control-Allow-Origin', '*')
          .append('Accept', 'image/svg+xml');
        return (
          this.httpClient
            .post(`${environment.kvPlotService}/energy-needs-annual`, payload, { headers, responseType: 'blob' })
            // .post(`http:localhost:3000/energy-needs-annual`, payload, { headers, responseType: 'blob' })
            .pipe(
              untilDestroyed(this),
              retry({ count: 3, delay: 1500 }),
              switchMap((image: Blob) => {
                const blobToBase64 = (blob: Blob): Promise<string> => {
                  const reader = new FileReader();
                  reader.readAsDataURL(blob);
                  return new Promise((resolve) => {
                    reader.onloadend = () => {
                      resolve(reader.result as string);
                    };
                  });
                };
                return from(blobToBase64(image));
              }),
              map((base64: string) => RealEstateActions.getLCAPlotAnnualEnergySuccess({ base64 })),
              catchError((error) => of(RealEstateActions.getLCAPlotAnnualEnergyError(error))),
            )
        );
      }),
    );
  });

  plotGEAK$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RealEstateActions.estimateLCACostsSuccess),
      // ofType(RealEstateActions.getLCAPlotCRREMNonRenewableEnergy),
      concatLatestFrom(() => this.store.select(realEstatesFeature.selectWithdrawDTO)),
      concatMap(([request, filters]) => {
        const deepCopyEnergyPlotDTO = _.cloneDeep(filters.outputDTO.lcaOutputPlot.geakPlotDTO);
        deepCopyEnergyPlotDTO.forEach((element) => {
          element.height = 50;
          element.width = 355;
        });
        var payload = deepCopyEnergyPlotDTO;

        const headers = new HttpHeaders()
          .set('content-type', 'application/json')
          // .set('Access-Control-Allow-Origin', '*')
          .append('Accept', 'image/svg+xml');
        return (
          this.httpClient
            .post(`${environment.kvPlotService}/geak`, payload, { headers, responseType: 'blob' })
            // .post(`http:localhost:3000/energy-needs-annual`, payload, { headers, responseType: 'blob' })
            .pipe(
              untilDestroyed(this),
              retry({ count: 3, delay: 1500 }),
              switchMap((image: Blob) => {
                const blobToBase64 = (blob: Blob): Promise<string> => {
                  const reader = new FileReader();
                  reader.readAsDataURL(blob);
                  return new Promise((resolve) => {
                    reader.onloadend = () => {
                      resolve(reader.result as string);
                    };
                  });
                };
                return from(blobToBase64(image));
              }),
              map((base64: string) => RealEstateActions.getLCAPlotGEAKSuccess({ base64 })),
              catchError((error) => of(RealEstateActions.getLCAPlotGEAKError(error))),
            )
        );
      }),
    );
  });

  presetGeoMetrics$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RealEstateActions.estimateGeoMetrics),
      //we don't want to blast the preset API with calls - so we add a debounce to at least channel  few updates together - this happends mainly when loading a object - or rapid updates on the form.
      debounceTime(200),
      concatLatestFrom(() => this.store.select(realEstatesFeature.selectRealEstateProgress)),
      distinctUntilChanged((previous, current) => JSON.stringify(previous) === JSON.stringify(current)),
      switchMap(([request, filters]) => {
        const requestDTO = _.cloneDeep(filters);
        console.log('presetGeoMetrics$ - requestDTO: ', requestDTO);
        return this.httpClient
          .post<RealEstateDTO>(`${environment.serverUrl}/api/withdrawal/geo-metrics/estimate`, requestDTO)
          .pipe(
            untilDestroyed(this),

            map((realEstateDTO: RealEstateDTO) => {
              return RealEstateActions.estimateGeoMetricsSuccess({ data: realEstateDTO });
            }),
            catchError((error) => {
              console.warn('/api/withdrawal/geo-metrics/estimate error: ', error);
              return of(RealEstateActions.estimateGeoMetricsError(error));
            }),
          );
      }),
    );
  });

  private postProcessEmptyRealEstateObject(realEstateObj: OutRealEstateDTO, wtp: WithdrawalProductType) {
    switch (wtp) {
      case 'OPERATING_COSTS':
        realEstateObj.additions.length = 0;
        realEstateObj.additions.push({} as AdditionEntryDTO);
        break;

      case 'INSURANCE_VALUES_GVBS':
        realEstateObj.additions.push({
          label: 'Reserve',
          bkp: null,
        } as AdditionEntryDTO);
        break;

      default:
        realEstateObj.additions.push({
          label: 'Reserve',
          bkp: 'BKP_6_RESERVE',
        } as AdditionEntryDTO);
        realEstateObj.additions.push({
          label: '',
          bkp: null,
        } as AdditionEntryDTO);
    }

    realEstateObj.energy = {
      current: {
        pvPeakPower: null,
        pvPeakPowerUserInput: null,
        pvPeakPowerUserPlaceholder: null,
        pvExport: null,
        hotWaterFromHeating: null,
        heatPowerUserInput: null,
        batteryCapacityUserInput: null,
        batteryCapacityPlaceholder: null,
        annualPvRoofProduction: null,
        annualPvRoofProductionUserInput: null,
        annualPvFacadeProduction: null,
        annualPvFacadeProductionUserInput: null,
        energySource: null,
        annualHeatingNeeds: null,
        annualHeatingNeedsPlaceholder: null,
        annualHeatingNeedsUserInput: null,
        annualCoolingNeeds: null,
        annualCoolingNeedsPlaceholder: null,
        annualCoolingNeedsUserInput: null,
        annualDhwNeeds: null,
        annualDhwNeedsPlaceholder: null,
        annualDhwNeedsUserInput: null,
        annualThermalSolarProduction: null,
        annualLightningNeeds: null,
        annualLightningNeedsPlaceholder: null,
        annualLightningNeedsUserInput: null,
        annualApplianceNeedsUserInput: null,
        annualApplianceNeeds: null,
        annualApplianceNeedsPlaceholder: null,
        annualNonEPBNeedsUserInput: null,
        annualNonEPBNeeds: null,
        annualNonEPBNeedsPlaceholder: null,
        annualTransportNeedsUserInput: null,
        annualTransportNeeds: null,
        annualTransportNeedsPlaceholder: null,
        annualDehumidificationNeeds: null,
        annualDehumidificationNeedsPlaceholder: null,
        annualDehumidificationNeedsUserInput: null,
        annualHumidificationNeeds: null,
        annualHumidificationNeedsPlaceholder: null,
        annualHumidificationNeedsUserInput: null,
        pvRoofProductionEnabled: null,
        pvFacadeProductionEnabled: null,
        batteryCapacityEnabled: null,
      },
      target: undefined,
    };
    realEstateObj.usages.push({
      type: null,
      standard: null,
      percentage: null,
      numFuUsage: null,
      comfortClass: 'CC3',
    } as RealEstateUsageEmbeddable);

    realEstateObj.quality.facadeTypes = [
      // {
      //   facadeType: 'MASSIVBAU_EDEL',
      //   uValue: 1,
      //   sqrMeter: 2016,
      //   orientation: 'SEV',
      // },
    ];

    realEstateObj.quality.heatingContainers = [
      // {
      //   heatingType: 'HEAT_PUMP_GEOTHERMAL',
      //   distribution: 'WUE_2',
      //   percentage: 100,
      //   fossileAmount: null,
      // },
    ];

    realEstateObj.quality.roofTypes = [];
  }
}
