import { Injectable, inject } from '@angular/core';
import { NormalDistributionService } from '../../distribution/normal-distribution.service';
import { ComponentMeasureService } from '../component-measure/component-measure.service';
import { RebuildComponentSliderService } from '../rebuild-component-slider/rebuild-component-slider.service';
import { ComponentMeasure } from '@generated/component-measure';
import { ComponentDTO } from '@generated/generatedEntities';
import { Logger } from '@shared';

const log = new Logger('RebuildTotalMeasureService');
@Injectable({
  providedIn: 'root',
})
export class RebuildTotalMeasureService {
  private _normalDistributionService = inject(NormalDistributionService);
  private _rebuildComponentSliderService = inject(RebuildComponentSliderService);
  // Copied file rebuild_intervention_grade_gap_config.json here.
  interventionGradeGaps: any = {
    CONSTRUCTION: { gap: { from: 0.8, to: 1.0 } },
    FLATROOF: { gap: { from: 0.4, to: 0.8 } },
    HEAT_GENERATION: { gap: { from: 0.4, to: 0.8 } },
    HEAT_DISTRIBUTION: { gap: { from: 0.4, to: 0.8 } },
    VENTILATION_GENERATION: { gap: { from: 0.25, to: 0.8 } },
    VENTILATION_DISTRIBUTION: { gap: { from: 0.25, to: 0.8 } },
    CLIMA_CHILLER_GENERATION: { gap: { from: 0.25, to: 0.8 } },
    CLIMA_CHILLER_DISTRIBUTION: { gap: { from: 0.25, to: 0.8 } },
    SANITARY_APPARATUS: { gap: { from: 0.25, to: 0.8 } },
    SANITARY_PIPES: { gap: { from: 0.25, to: 0.8 } },
  };

  calculateTotalMeasure(component: ComponentDTO, rebuildEstate: any): ComponentMeasure {
    try {
      if (component.totalMeasure == null) {
        component.totalMeasure = new ComponentMeasure();
        // set default values for first time calculation.
        (component.totalMeasure as ComponentMeasure).setInterventionGradeQuantityBackend(100);
      }
      var res = new ComponentMeasure();
      // copy values that do not change from old totalMeasure.
      res.name = component.totalMeasure.name;
      res.setMeasureUserInput(component.totalMeasure.measureUserInput);
      res.setInterventionGradeQuantityBackend(component.totalMeasure.interventionGradeQuantityBackend);
      res.setInterventionGradeQuantityUserInput(component.totalMeasure.interventionGradeQuantityUserInput);
      res.setInterventionGradeUserInput(component.totalMeasure.interventionGradeUserInput);
      // set new values based on modification and modernization measures
      var sigmaModernization = component.mainModernizationMeasure.interventionGradeSigmaBackend;
      var nd;
      if (!component.activeInRealEstate && component.active) {
        // component will be new in targetOverhaul => 100% intervention grade
        nd = this._normalDistributionService.get(100, 10);
      } else if (component.changing) {
        // component changes from realEstate to targetOverhaul => 100% intervention grade
        nd = this._normalDistributionService.get(100, 10);
      } else {
        // component does not change from realEstate to targetOverhaul
        if (component.modificationMeasure != null) {
          // component is influenced by modification => combine mainModernization and modificaion intervention grade
          var modificationPart = component.modificationMeasure.interventionGradeQuantity! / 100;
          var mainModernizationPart =
            component.mainModernizationMeasure.interventionGradeQuantity! / 100 - modificationPart;
          var sigmaModification = component.modificationMeasure.interventionGradeSigmaBackend;
          nd = this._normalDistributionService.add(
            this._normalDistributionService.get(
              component.mainModernizationMeasure.interventionGrade! * mainModernizationPart,
              sigmaModernization != null ? Number(sigmaModification) : 0,
            ),
            this._normalDistributionService.get(
              component.modificationMeasure.interventionGrade! * modificationPart,
              sigmaModification != null ? Number(sigmaModification) : 0,
            ),
          );
        } else {
          // component is not influenced by modification => take mainModernization intervention grade
          nd = this._normalDistributionService.get(
            (component.mainModernizationMeasure.interventionGrade! *
              component.mainModernizationMeasure.interventionGradeQuantity!) /
              100,
            sigmaModernization != null ? Number(sigmaModernization) : 0,
          );
        }
      }

      var componentGap = this.interventionGradeGaps[component.rebuildEstateComponent];
      if (
        component.rebuildEstateComponent === 'HEAT_GENERATION' &&
        rebuildEstate.quality.heatingTypes.includes('DISTRICT_HEATING')
      ) {
        componentGap = undefined; // Special case: Fernwärme does not have a gap => Remove gap.
      }
      var cdfInv =
        componentGap === undefined
          ? this._normalDistributionService.cdfInverse(nd)
          : this._normalDistributionService.invertCustomCdf(
              this._normalDistributionService.cdfCutInterval(
                nd,
                componentGap.gap.from * 100,
                componentGap.gap.to * 100,
              ),
            );
      var q =
        componentGap === undefined
          ? this._rebuildComponentSliderService.lookupQuantilesInterventionGrade(cdfInv)
          : this._rebuildComponentSliderService.lookupQuantilesInterventionGradeWithGap(
              cdfInv,
              componentGap.gap.from * 100,
              componentGap.gap.to * 100,
            );

      // set measure
      res.setMeasureBackend(
        res.getMeasureValuesBasedOnInterventionGrade(q.total_intervention_grade),
        q.quantiles,
        q.gap,
      );

      // set intervention grade
      if (
        component.totalMeasure.measureUserInput == null ||
        component.totalMeasure.measureUserInput === res.measureBackend
      ) {
        // User has not selected measure => Take suggested intervention grade from normal distribution.
        if (Number.isFinite(q.total_intervention_grade)) {
          res.setInterventionGradeBackend(q.total_intervention_grade); // nd.sigma being pass as argument but function accepts only one param :: removing ng.sigma param ? ask LUKAS
        } else {
          // log.debug('total_intervention_grade', q.total_intervention_grade, component);
        }
      } else if (component.totalMeasure.measureUserInput === 'MK5') {
        // User has selected MK5 => Set suggested Intervention grade to 100.
        res.setInterventionGradeBackend(100); // nd.sigma being pass as argument but function accepts only one param :: removing ng.sigma param ? ask LUKAS
      } else {
        // User has selected MK1, MK2, MK3 or MK4 => Set suggested intervention grade in range suggested by selected measure by using the normal distribution.
        var igs = res.getIntervalFromMeasure(component.totalMeasure.measureUserInput);
        var cdfWithoutGap = this._normalDistributionService.cdf(nd); // take cdf without gaps to predict intervention grade based on selected measure.
        var cdfInvWithoutGap = this._normalDistributionService.cdfInverse(nd); // take cdf inverse without gaps to predict intervention grade based on selected measure.
        var cdfFrom = cdfWithoutGap(igs[0]);
        var cdfTo = cdfWithoutGap(igs[1]);
        if (cdfFrom !== cdfTo) {
          // set intervention grade to middle point of cdf in interval given by user selected measure.
          res.setInterventionGradeBackend(cdfInvWithoutGap((cdfFrom + cdfTo) / 2)); // nd.sigma being pass as argument but function accepts only one param :: removing ng.sigma param ? ask LUKAS
        } else {
          // cdfFrom and cdfTo are the same (e.g. both 0), which can only be due to floating point precision. In this outlier case simply take the average of the intervention grades for user selected measure.
          res.setInterventionGradeBackend((igs[0] + igs[1]) / 2); // nd.sigma being pass as argument but function accepts only one param :: removing ng.sigma param ? ask LUKAS
        }
      }
      return res;
    } catch (e) {
      console.warn('total mesaure 142', e);
      return {} as ComponentMeasure;
    }
  }
}
