import { inject } from '@angular/core';
import { createActionGroup, createFeature, createReducer, emptyProps, props, Store } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';

export const bimModelFeatureKey = 'bimModel';

export enum BIM_MODEL_STATUS {
  INIT = 'INIT',
  UPLOADING = 'UPLOADING',
  UPLOADED = 'UPLOADED',
  PORCESSING = 'PROCESSING',
  PROCESSED = 'PROCESSED',
  ERROR = 'ERROR',
  DONE = 'DONE',
  ENRICHED_IN_PROGRESS = 'ENRICHED_IN_PROGRESS',
  ENRICHED_DONE = 'ENRICHED_DONE',
  ENRICHED_FAILED = 'ENRICHED_FAILED',
  EXTRACTED_ORIGINAL_DONE = 'EXTRACTED_ORIGINAL_DONE',
  EXTRACTED_ENRICHED = 'EXTRACTED_ENRICHED',
  EXTRACTED_ENRICHED_DONE = 'EXTRACTED_ENRICHED_DONE',
  EXTRACTED_ENRICHED_IN_PROGRESS = 'EXTRACTED_ENRICHED_IN_PROGRESS',
  EXTRACTED_FAILED = 'EXTRACTED_FAILED',
  EXTRACTED_ENRICHED_FAILED = 'EXTRACTED_ENRICHED_FAILED',
  //   ENRICHED
  // ENRICHED_FAILED
  // EXTRACTED_ORIGINAL
  // EXTRACTED_ENRICHED
  // EXTRACTED_FAILED
  // EXTRACTED_ENRICHED_FAILED
}

export interface BimModel {
  modelName: string;
  json: any;
  glb: any;
  parameters: any;
  checkReport: any;
  id: number;
  status?: BIM_MODEL_STATUS;
}

export interface BimModelState {
  bimModel: BimModel | null;
  collection: BimModel[];
  status: BIM_MODEL_STATUS;
  upLoadProgress: number | null;
  // bimModelJson: any | null;
  bimModelJson: { [index: string]: string } | null;
}

export const initialState: BimModelState = {
  bimModel: null,
  collection: [],
  status: BIM_MODEL_STATUS.INIT,
  upLoadProgress: null,
  bimModelJson: null,
};

export const BimModelActions = createActionGroup({
  source: 'BimModel/API',
  events: {
    enter: emptyProps(),
    'BimModel Selected': props<{ id: number }>(),
    'BimModel Loading': props<{ id: number }>(),
    'BimModel Enrich': props<{ id: number }>(),
    'BimModel Test': props<{ id: number }>(),
    'BimModel Upload': props<{ formData: FormData }>(),
    'BimModel Upload Started': emptyProps(),
    'BimModel Upload Progress': props<{ progress: number }>(),
    'BimModel Upload Cancel': props<{ id: number }>(),
    'BimModel Upload Success': props<{ id: number; modelName: string }>(),
    'BimModel Upload Error': props<{ error: string }>(),
    'BimModel Process Status': props<{ id: number }>(),
    'BimModel Loaded Success': props<{ bimModel: BimModel }>(),
    'BimModel Loaded Error': props<{ bimModel: BimModel }>(),
    'BimModels Load All': emptyProps(),
    'BimModels Loaded Success': props<{ bimModels: BimModel[] }>(),
    'BimModels Loaded Failure': props<{ error: string }>(),
    'BimModel Start Polling': emptyProps(),
    'BimModel Still Polling': props<{ msg: string }>(),
    'BimModel End Polling': emptyProps(),
  },
});

const reducer = createReducer(
  initialState,

  immerOn(BimModelActions.bimModelLoadedSuccess, (draft: BimModelState, data) => {
    draft.bimModel = {
      modelName: data.bimModel.modelName,
      id: data.bimModel.id,
      json: data.bimModel.json,
      glb: '<<large file not stored here>>',
      checkReport: data.bimModel.checkReport,
      parameters: data.bimModel.parameters,
    };
    // bimViewerService.storeLargeFileData(data.bimModel.glb);
    console.warn(data.bimModel.status);
    console.warn(data.bimModel.checkReport.length);
    if (BIM_MODEL_STATUS.ENRICHED_DONE == data.bimModel.status) {
      draft.bimModelJson = JSON.parse(atob(data.bimModel.parameters));
    }
  }),
  immerOn(BimModelActions.bimModelUploadStarted, (draft: BimModelState, data) => {
    // console.warn('bimModelUploadStarted');
    draft.status = BIM_MODEL_STATUS.UPLOADING;
  }),
  immerOn(BimModelActions.bimModelUploadProgress, (draft: BimModelState, data) => {
    // console.error('bimModelUploadProgress');
    // draft.status = BIM_MODEL_STATUS.UPLOADING;
    draft.upLoadProgress = data.progress;
  }),
  immerOn(BimModelActions.bimModelUploadSuccess, (draft: BimModelState, data) => {
    draft.status = BIM_MODEL_STATUS.UPLOADED;
    draft.bimModel = {
      modelName: data.modelName,
      id: data.id,
      json: null,
      glb: null,
      checkReport: null,
      parameters: null,
    };
    draft.upLoadProgress = 100;
  }),
  immerOn(BimModelActions.bimModelUploadError, (draft: BimModelState, data) => {
    // console.error('bimModelUploadError');
    draft.status = BIM_MODEL_STATUS.ERROR;
  }),
  immerOn(BimModelActions.bimModelLoadedError, (draft: BimModelState, data) => {
    console.error('bimModelLoadedError', data);
    if (data.bimModel.status) {
      draft.status = data.bimModel.status;
    } else {
      console.error('bimModel.status not defined');
    }
  }),

  immerOn(BimModelActions.bimModelsLoadedSuccess, (draft: BimModelState, data) => {
    draft.collection = data.bimModels;
  }),
);

export const bimModelFeature = createFeature({
  name: bimModelFeatureKey,
  reducer: reducer,

  extraSelectors: ({ selectBimModel }) => ({
    // selectCurrentProduct: createSelector(selectBimModel, (products, id) =>
    //   products.find((product) => product.id === id),
    // ),
  }),
});

export const { selectBimModel } = bimModelFeature;

// export function provideBimModelFeature() {
//   return makeEnvironmentProviders([provideState(bimModelFeature), provideEffects({ loadBimModel$: loadBimModel$ })]);
// }

export function injectBimModelFeature() {
  const store = inject(Store);
  return {
    bimModel$: store.select(bimModelFeature.selectBimModel),
  };
}
