import { Injectable, inject } from '@angular/core';
import { CompactChillerTypeSelectService } from '@app/calculators/components/compact-heating-type-select/compact-chiller-type_select/compact-chiller-type-select.service';
import { CompactHeatingTypeSelectService } from '@app/calculators/components/compact-heating-type-select/compact-heating-type_select/compact-heating-type-select.service';
import { CompactVentilationTypeSelectService } from '@app/calculators/components/compact-heating-type-select/compact-ventilation-type_select/compact-ventilation-type-select.service';
import { SharedControllerFnService } from '../../shared/shared-controller-fn.service';
import { RebuildEstateDataService } from '../rebuild-estate-data/rebuild-estate-data.service';
import {
  ChillerType,
  CompleteRenovationTemplateType,
  ElevatorDTO,
  ExteriorRenovationTemplateType,
  FacadeType,
  HeatingType,
  InteriorRenovationTemplateType,
  RebuildEstateComponent,
  RenovationTemplateContainer,
  RoofCoarseType,
  TemplateType,
  VentilationType,
} from '@generated/generatedEntities';
import { Logger } from '@shared';
import _ from 'lodash';

const log = new Logger('RebuildTemplateService');

@Injectable({
  providedIn: 'root',
})
export class RebuildTemplateService {
  private _sharedControllerFnService = inject(SharedControllerFnService);
  private _rebuildEstateDataService = inject(RebuildEstateDataService);
  private _heatingTypeDataService = inject(CompactHeatingTypeSelectService);
  private _compactChillerTypeSelectService = inject(CompactChillerTypeSelectService);
  private _compactVentilationTypeSelectService = inject(CompactVentilationTypeSelectService);
  templates: TemplateType[] = [
    'NONE',
    'ALL',
    'CONSTRUCTION',
    'SHELL',
    'FITOUT',
    'ANNEX',
    'ADDITION_STORY',
    'ADDITION_HNF',
  ];

  presets: { [index in CompleteRenovationTemplateType]?: RebuildEstateComponent[] } = {
    CONSTRUCTION: ['CONSTRUCTION', 'FLOORING'],
    WALLCOVERING: ['WALLCOVERING'],
    BALCONY: ['CONSTRUCTION', 'WALLCOVERING', 'COMPONENTS_EXTERNAL_WALL', 'FLATROOF'],
    COMPONENTS_EXTERNAL_WALL: ['COMPONENTS_EXTERNAL_WALL'],
    ROOF: ['INCLINEDROOF', 'FLATROOF'],
    ELECTRICITY: ['ELECTRIC_HIGH_CURRENT', 'ELECTRIC_LOW_CURRENT'],
    HEAT: ['HEAT_GENERATION', 'HEAT_DISTRIBUTION'],
    VENTILATION: [
      'VENTILATION_GENERATION',
      'VENTILATION_DISTRIBUTION',
      'CLIMA_CHILLER_GENERATION',
      'CLIMA_CHILLER_DISTRIBUTION',
    ],
    SANITARY: ['SANITARY_APPARATUS', 'SANITARY_PIPES', 'FLOORING'],
    INSTALLATION__SURFACES: ['INSTALLATION', 'FLOORING'],
    KITCHEN: [
      'ELECTRIC_HIGH_CURRENT',
      'ELECTRIC_LOW_CURRENT',
      'SANITARY_APPARATUS',
      'SANITARY_PIPES',
      'KITCHEN',
      'FLOORING',
    ],
    TRANSPORT_INSIDE: ['CONSTRUCTION', 'ELECTRIC_HIGH_CURRENT', 'FLOORING', 'TRANSPORTATIONSYSTEM'],
    TRANSPORT_OUTSIDE: [
      'CONSTRUCTION',
      'WALLCOVERING',
      'COMPONENTS_EXTERNAL_WALL',
      'ELECTRIC_HIGH_CURRENT',
      'FLATROOF',
      'TRANSPORTATIONSYSTEM',
      'ENVIRONMENT',
    ],
    UNDERGROUND_PARKING: ['CONSTRUCTION', 'FLOORING'],
    SURROUNDINGS: ['ENVIRONMENT'],
  };
  dictTemplateToRegisteredIdents: any = {};
  dictIdentToRegisteredTemplates: any = {};

  initializeData() {}

  isNone(selectedTemplates?: TemplateType[]) {
    // if (selectedTemplates == null) {
    //   log.error(`selected templates are null`);
    //   return false;
    // }
    // return selectedTemplates.includes('NONE') || this.getSelectedTemplates().includes('NONE');
    return _.includes(selectedTemplates || this.getSelectedTemplates(), 'NONE');
  }
  isAnnex(selectedTemplates?: TemplateType[]) {
    // if (selectedTemplates == null) {
    //   log.error(`selected templates are null`);
    //   return false;
    // }
    // return selectedTemplates.includes('ANNEX') || this.getSelectedTemplates().includes('ANNEX');
    return _.includes(selectedTemplates || this.getSelectedTemplates(), 'ANNEX');
  }
  isAdditionStory(selectedTemplates?: any) {
    // if (selectedTemplates == null) {
    //   log.error(`selected templates are null`);
    //   return false;
    // }
    // return selectedTemplates.includes('ADDITION_STORY') || this.getSelectedTemplates().includes('ADDITION_STORY');
    return _.includes(selectedTemplates || this.getSelectedTemplates(), 'ADDITION_STORY');
  }

  isOnlyTargetOverhaul() {
    return !this.isAnnex() && !this.isAdditionStory();
  }
  getSelectedTemplates() {
    return this._rebuildEstateDataService.getRebuildEstate().selectedTemplates;
  }

  addTemplateIdent(template: any, ident: any) {
    var idents = this.dictTemplateToRegisteredIdents[template];
    if (idents && idents.indexOf(ident) === -1) {
      idents.push(ident);
      this.dictTemplateToRegisteredIdents[template] = idents;
    } else {
      this.dictTemplateToRegisteredIdents[template] = [ident];
    }

    var templates = this.dictIdentToRegisteredTemplates[ident];
    if (templates && templates.indexOf(template) === -1) {
      templates.push(template);
      this.dictIdentToRegisteredTemplates[ident] = templates;
    } else {
      this.dictIdentToRegisteredTemplates[ident] = [template];
    }
  }

  _merge(components: any, newComponents: any) {
    if (newComponents === undefined) return components;
    return newComponents.reduce((a: any, b: any) => {
      if (a.indexOf(b) < 0) a.push(b);
      return a;
    }, components);
  }

  getComponents(templateContainer: RenovationTemplateContainer, isAnnex: boolean) {
    var components: RebuildEstateComponent[] = [];
    var realEstate = this._rebuildEstateDataService.getRebuildEstate();
    if (realEstate == null) return components;
    var presetKeys: (
      | InteriorRenovationTemplateType[]
      | ExteriorRenovationTemplateType[]
      | CompleteRenovationTemplateType[]
    )[] = [];
    var flattenPresetKeys: (
      | InteriorRenovationTemplateType
      | ExteriorRenovationTemplateType
      | CompleteRenovationTemplateType
    )[] = [];
    if (templateContainer == null || templateContainer.groundwork.length === 0) {
      flattenPresetKeys = [];
    } else if (
      templateContainer.groundwork.indexOf('COMPLETE_RENOVATION') > -1 ||
      templateContainer.groundwork.indexOf('UNKNOWN') > -1
    ) {
      flattenPresetKeys = templateContainer.complete;
    } else {
      presetKeys.push(templateContainer.interior);
      presetKeys.push(templateContainer.exterior);
      presetKeys = _.compact(presetKeys);
      flattenPresetKeys = _.flattenDeep(presetKeys);
    }

    if (isAnnex && !_.includes(flattenPresetKeys, 'SURROUNDINGS')) {
      flattenPresetKeys.push('SURROUNDINGS');
    }

    if (flattenPresetKeys != null && flattenPresetKeys.length > 0) {
      _.forEach(flattenPresetKeys, (value) => {
        components = this._merge(components, (this.presets as { [key: string]: string })[value]);
      });
    }
    return components;
  }

  _filterComponents(components: RebuildEstateComponent[], componentsToFilter: RebuildEstateComponent[]) {
    return components.filter((component: RebuildEstateComponent) => {
      return !componentsToFilter.includes(component);
    });
  }

  getNonChangingComponents(
    components: RebuildEstateComponent[],
    ventilationType: VentilationType,
    chillerType: ChillerType | null,
    elevatorInside: ElevatorDTO,
    elevatorOutside: ElevatorDTO,
    facadeType: FacadeType,
    roofTypes: RoofCoarseType[] | null,
    heatingTypes: HeatingType[],
    builtUpArea: number,
    targetOverhaulVentilationType: VentilationType,
    targetOverhaulChillerType: ChillerType | null,
    targetOverhaulElevatorInside: ElevatorDTO,
    targetOverhaulElevatorOutside: ElevatorDTO,
    targetOverhaulFacadeType: FacadeType,
    targetOverhaulRoofTypes: RoofCoarseType[],
    targetOverhaulHeatingTypes: HeatingType[],
    targetOverhaulBuiltUpArea: number,
  ) {
    if (ventilationType !== targetOverhaulVentilationType)
      components = this._filterComponents(components, ['VENTILATION_GENERATION', 'VENTILATION_DISTRIBUTION']);

    if (chillerType !== targetOverhaulChillerType)
      components = this._filterComponents(components, ['CLIMA_CHILLER_GENERATION', 'CLIMA_CHILLER_DISTRIBUTION']);

    if (!_.isEqual(_.sortBy(heatingTypes), _.sortBy(targetOverhaulHeatingTypes)))
      components = this._filterComponents(components, ['HEAT_GENERATION', 'HEAT_DISTRIBUTION']);

    if (facadeType !== targetOverhaulFacadeType)
      components = this._filterComponents(components, ['WALLCOVERING', 'COMPONENTS_EXTERNAL_WALL']);

    if (
      roofTypes != null &&
      ((!roofTypes.includes('INCLINEDROOF') && targetOverhaulRoofTypes.includes('INCLINEDROOF')) ||
        (roofTypes.includes('INCLINEDROOF') && !targetOverhaulRoofTypes.includes('INCLINEDROOF')))
    )
      components = this._filterComponents(components, ['INCLINEDROOF']);

    if (
      roofTypes != null &&
      ((!roofTypes.includes('FLATROOF') && targetOverhaulRoofTypes.includes('FLATROOF')) ||
        (roofTypes.includes('FLATROOF') && !targetOverhaulRoofTypes.includes('FLATROOF')))
    )
      components = this._filterComponents(components, ['FLATROOF']);

    if (
      elevatorInside != null &&
      targetOverhaulElevatorInside != null &&
      elevatorInside['verticalDecision'] !== targetOverhaulElevatorInside['verticalDecision'] &&
      elevatorOutside != null &&
      targetOverhaulElevatorOutside != null &&
      elevatorOutside['verticalDecision'] !== targetOverhaulElevatorOutside['verticalDecision']
    )
      components = this._filterComponents(components, ['TRANSPORTATIONSYSTEM']);

    return components;
  }

  getActiveComponents(
    components: RebuildEstateComponent[],
    ventilationType: VentilationType,
    chillerType: any,
    elevatorInside: any,
    elevatorOutside: any,
    facadeType: FacadeType,
    roofTypes: RoofCoarseType[] | null,
    heatingTypes: HeatingType[],
    builtUpArea: any,
  ) {
    if (facadeType === 'KEINE_FASSADE')
      components = this._filterComponents(components, ['WALLCOVERING', 'COMPONENTS_EXTERNAL_WALL']);

    if (!roofTypes?.includes('INCLINEDROOF')) components = this._filterComponents(components, ['INCLINEDROOF']);

    if (!roofTypes?.includes('FLATROOF')) components = this._filterComponents(components, ['FLATROOF']);

    if (heatingTypes.includes(this._heatingTypeDataService.NO_HEATING.value))
      components = this._filterComponents(components, ['HEAT_GENERATION', 'HEAT_DISTRIBUTION']);

    if (ventilationType === this._compactVentilationTypeSelectService.NONE.value)
      components = this._filterComponents(components, ['VENTILATION_GENERATION', 'VENTILATION_DISTRIBUTION']);

    if (chillerType === this._compactChillerTypeSelectService.NO.value)
      components = this._filterComponents(components, ['CLIMA_CHILLER_GENERATION', 'CLIMA_CHILLER_DISTRIBUTION']);

    if (
      elevatorInside != null &&
      (elevatorInside.vertical == null || elevatorInside.vertical == 0) &&
      elevatorInside['verticalDecision'] !== 'yes' &&
      elevatorOutside != null &&
      (elevatorOutside.vertical == null || elevatorOutside.vertical == 0) &&
      elevatorOutside['verticalDecision'] !== 'yes'
    )
      components = this._filterComponents(components, ['TRANSPORTATIONSYSTEM']);

    if (builtUpArea === 0) components = this._filterComponents(components, ['ENVIRONMENT']);

    return components;
  }

  register(ident: any, templates: any) {
    // registers templates to an ident
    // at least one template must mach to the ident in order to make doShow return true
    // check inputs
    templates.forEach((template: any) => {
      if (templates.indexOf(template) === -1) {
        //$log.error("Template " + template + " is unknown");
      }
    });
    templates.forEach((template: any) => {
      this.addTemplateIdent(template, ident);
    });
  }

  selectTemplate(newTemplate: any) {
    //$log.info("selectTemplate: " + newTemplate);
    if (this.getSelectedTemplates().indexOf(newTemplate) === -1) {
      this.getSelectedTemplates().push(newTemplate);
    }
  }

  deselectTemplate(oldTemplate: any) {
    //$log.info("oldTemplate: " + oldTemplate);
    var index = this.getSelectedTemplates().indexOf(oldTemplate);
    if (index !== -1) {
      this.getSelectedTemplates().splice(index, 1);
    }
  }

  handleSpecialCaseChiller(components: any, isUsages: any, targetOverhaulUsages: any) {
    var hasUsageChiller = this._sharedControllerFnService.hasUsageArrayWithChiller(
      _.concat(isUsages, targetOverhaulUsages),
    );
    if (!hasUsageChiller) {
      components = components.filter((component: any) => {
        return !['CLIMA_CHILLER_GENERATION', 'CLIMA_CHILLER_DISTRIBUTION'].includes(component);
      });
    }
    return {
      hasUsageChiller: hasUsageChiller,
      components: components,
    };
  }

  doShow(ident: any) {
    // indicates whether to show the provided ident and it's html
    // shows if one of the registered templates matches to the provided ident
    var templates = this.dictIdentToRegisteredTemplates[ident];

    // one of these templates has to be selected
    if (templates) {
      for (var i = 0; i < templates.length; i++) {
        if (this.getSelectedTemplates().indexOf(templates[i]) > -1) {
          return true;
        }
      }
    }
    return false;
  }
}
