import { environment } from '@env/environment';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, isDevMode, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { ApiPrefixInterceptor, ErrorHandlerInterceptor, RouteReusableStrategy, SharedModule } from '@shared';
import { AuthModule } from '@app/auth';
import { ShellModule } from './shell/shell.module';
import { AppRoutingModule } from './app-routing.module';
import { TranslocoRootModule } from './transloco-root.module';
import { LandingPageModule } from './landing-page/landing-page.module';
import { AnimatedDigitModule } from './animated/animated-digit/animated-digit.module';
import { RegistrationModule } from './registration/registration.module';
import { AuthInterceptor } from '@app/auth/interceptor/auth.interceptor';
import { ResetPasswordModule } from './reset-password/reset-password.module';
import { DynamicImageModule } from './dynamic-image/dynamic-image.module';
import { MatDialogModule } from '@angular/material/dialog';
import { WebsiteFragmentsModule } from './website_fragments/website-fragments.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import * as Sentry from '@sentry/angular';
import { provideZxvbnServiceForPSM } from 'angular-password-strength-meter/zxcvbn';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { realEstatesFeature } from '@app/core/services/store/real-estate.state';
import { EffectsModule } from '@ngrx/effects';
import { RealEstateEffects } from '@app/core/services/store/real-estate.effects';
import { registerLocaleData } from '@angular/common';
import localeDeCH from '@angular/common/locales/de-CH';
import localeDeCHExtra from '@angular/common/locales/extra/de-CH';
import {
  popperVariation,
  provideTippyConfig,
  TippyDirective,
  tooltipVariation,
  withContextMenuVariation,
} from '@ngneat/helipopper';
import { AppComponent } from '@app/app.component';
import { bimModelFeature } from '@app/calculators/kv-to-bim/store/bim-model';
import { BimModelEffects } from '@app/calculators/kv-to-bim/store/bim-model.effects';
import { calculatorIntegrationFeature } from '@app/calculators/integrations/state/calculator-integration.reducer';

registerLocaleData(localeDeCH, 'de-CH', localeDeCHExtra);

Sentry.init({
  dsn: environment.sentry_dsn,
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    // new Sentry.BrowserTracing({
    //   routingInstrumentation: Sentry.routingInstrumentation,
    // }),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    //FIXME currently disabled due to performance issues - might have to do with ngDoCheck?
    // new Sentry.Replay({
    //   maskAllText: true,
    //   blockAllMedia: true,
    // }),
  ],
  environment: environment.profile,
  release: environment.version ?? '',
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: environment.production ? 0.3 : 0.1,
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^\//],
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    // ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    ServiceWorkerModule.register('./safe-worker.js', { enabled: false }),
    FormsModule,
    RouterModule,
    TranslocoRootModule,
    BrowserAnimationsModule,
    MaterialModule,
    SharedModule,
    ShellModule,
    LandingPageModule,
    AuthModule,
    AnimatedDigitModule,
    RegistrationModule,
    // RegisterFormModule,
    ResetPasswordModule,
    DynamicImageModule,
    MatDialogModule,
    WebsiteFragmentsModule,
    StoreModule.forFeature(realEstatesFeature),
    StoreModule.forFeature(bimModelFeature),
    StoreModule.forFeature(calculatorIntegrationFeature),
    // StoreModule.forFeature(bimModelFeature.name, bimModelFeature.reducer),
    StoreModule.forRoot(realEstatesFeature.reducer, {
      // metaReducers: realEstatesFeature.reducer,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    StoreModule.forRoot(bimModelFeature.reducer, {
      // metaReducers: realEstatesFeature.reducer,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    StoreModule.forRoot(calculatorIntegrationFeature.reducer, {
      // metaReducers: realEstatesFeature.reducer,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot(RealEstateEffects),
    EffectsModule.forFeature(RealEstateEffects),
    EffectsModule.forFeature(BimModelEffects),
    StoreDevtoolsModule.instrument({
      name: isDevMode() ? 'keeValue-dev' : 'keeValue',
      maxAge: isDevMode() ? 25 : 10,
      logOnly: !isDevMode(),
      trace: isDevMode(),
      traceLimit: 75,
      autoPause: true,
      connectInZone: true,
      // actionsBlocklist: ['BimModel Loaded Success'],
    }),
    // StoreRouterConnectingModule.forRoot(),
    // must be imported as the last module as it contains the fallback route
    TippyDirective,
    AppRoutingModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiPrefixInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    provideTippyConfig({
      defaultVariation: 'popperBorder',
      variations: {
        tooltip: {
          ...tooltipVariation,
          trigger: 'manual',
          theme: 'light',
          interactive: false,
          allowHTML: true,
          hideOnClick: 'toggle',
          placement: 'right',
          zIndex: 1048,
          appendTo: () => document.body,
        },
        popper: {
          ...popperVariation,
          trigger: 'manual',
          theme: 'light',
          allowHTML: true,
          interactive: false,
          hideOnClick: 'toggle',
          placement: 'right',
          zIndex: 1048,
          appendTo: () => document.body,
        },
        menu: {
          ...popperVariation,
          trigger: 'manual',
          allowHTML: true,
          hideOnClick: 'toggle',
          placement: 'right',
          // appendTo: () => document.body,
          arrow: false,
          zIndex: 1048,
          offset: [0, 0],
        },
        contextMenu: withContextMenuVariation(popperVariation),
        popperBorder: {
          ...popperVariation,
          trigger: 'manual',
          theme: 'light-border',
          allowHTML: true,
          hideOnClick: 'toggle',
          placement: 'right',
          zIndex: 1048,
          interactive: false,
          appendTo: () => document.body,
        },
      },
      appendTo: 'parent',
      trigger: 'manual',
      placement: 'right',
      zIndex: 1048,
      hideOnClick: false,
    }),
    { provide: LOCALE_ID, useValue: 'de-CH' },
    provideZxvbnServiceForPSM(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
// platformBrowserDynamic()
//   .bootstrapModule(AppModule)
//   .then((moduleRef) => {
//     devTools({
//       maxAge: 100,
//       logTrace: true,
//       postTimelineUpdate: () => moduleRef.injector.get(ApplicationRef).tick(),
//     });
//   });
