import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  OutRealEstateDTO,
  RealEstateDTO,
  RealEstateUsageEmbeddable,
  RebuildEstateDTO,
  RebuildEstimationOutputDTO,
  WithdrawalProductType,
  WithdrawDTO,
} from '@generated/generatedEntities';
import { LcaBuildingElementPresetDTO } from '@generated/extended';

export const RealEstateActions = createActionGroup({
  source: 'RealEstate/API',
  events: {
    'Get Empty RealEstate': props<{ wpt: WithdrawalProductType; tasks: WithdrawalProductType[] }>(),
    'Load RealEstate': emptyProps(),
    'Reset Store': emptyProps(),
    'Patch RealEstate': props<{ data: RealEstateDTO }>(),
    'Load RealEstate Success': (data: OutRealEstateDTO) => data,
    'Update RealEstate': (data: RealEstateDTO | RebuildEstateDTO | OutRealEstateDTO) => data,
    'Update Progress on RealEstate': (data: RealEstateDTO | RebuildEstateDTO | OutRealEstateDTO) => data,
    'Load RealEstate Failure': props<{ error: unknown }>(),
    'Preset LCA': props<{ data: RealEstateDTO | RebuildEstateDTO | OutRealEstateDTO }>(),
    'Preset LCA Success': (data: LcaBuildingElementPresetDTO) => data,
    'Preset LCA  Noop': props<{ preconditions: boolean[] }>(),
    'Preset LCA Pv': props<{ data: RealEstateDTO | RebuildEstateDTO | OutRealEstateDTO }>(),
    'Preset LCA Pv Success': (data: { pvRoof: number }) => data,
    'Preset LCA Pv Error': props<{ error: unknown }>(),
    'Preset LCA Error': props<{ error: unknown }>(),
    'Preset LCA HP Power': props<{ data: RealEstateDTO | RebuildEstateDTO | OutRealEstateDTO }>(),
    'Preset LCA HP Power Noop': props<{ preconditions: boolean[] }>(),
    'Noop due to status': emptyProps(),
    'Preset LCA Functional Units': props<{ data: RealEstateDTO | RebuildEstateDTO | OutRealEstateDTO }>(),
    'Preset LCA Functional Units Success': props<{ data: RealEstateUsageEmbeddable[] }>(),
    'Preset LCA Functional Units Error': props<{ error: unknown }>(),
    'Preset LCA Functional Units Noop': props<{ preconditions: boolean[] }>(),
    //TODO type the any...
    'Preset LCA HP Power Success': props<{ data: number[] }>(),
    'Preset LCA HP Power Error': props<{ error: unknown }>(),
    'Estimate LCA Costs': props<{ data: RealEstateDTO | RebuildEstateDTO | OutRealEstateDTO }>(),
    'Estimate LCA Costs Success': (data: WithdrawDTO) => data,
    'Estimate LCA Costs Error': props<{ error: unknown }>(),
    // 'Estimate LCA Energy Needs': emptyProps(),
    'Estimate LCA Energy Needs': props<{ data: OutRealEstateDTO }>(),
    'Estimate LCA Energy Needs Success': (data: RebuildEstimationOutputDTO) => data,
    'Estimate LCA Energy Needs Failure': props<{ error: unknown }>(),
    'Estimate LCA Energy Needs Noop': props<{ preconditions: unknown }>(),
    'Estimate LCA Energy Performance': props<{ data: OutRealEstateDTO }>(),
    'Estimate LCA Energy Performance Success': (data: RebuildEstimationOutputDTO) => data,
    'Estimate LCA Energy Performance Failure': props<{ error: unknown }>(),
    'Estimate LCA Energy Needs and Performance': props<{ data: OutRealEstateDTO }>(),
    'Estimate LCA Energy Needs and Performance Noop': props<{ preconditions: unknown }>(),
    'Estimate LCA Energy Needs and Performance Success': props<{ data: RebuildEstimationOutputDTO }>(),
    'Estimate LCA Energy Needs and Performance Failure': props<{ error: unknown }>(),
    'Estimate LCA Grey Energy': emptyProps(),
    'Estimate LCA Grey Energy Success': (data: RebuildEstimationOutputDTO) => data,
    'Estimate LCA Grey Energy Failure': props<{ error: unknown }>(),
    'Get LCA Plot CRREM CO2': (data: any) => data,
    'Get LCA Plot CRREM CO2 Success': props<{ base64: string }>(),
    'Get LCA Plot CRREM CO2 Error': props<{ error: unknown }>(),
    'Get LCA Plot CRREM Non Renewable Energy': (data: any) => data,
    'Get LCA Plot CRREM Non Renewable Energy Success': props<{ base64: string }>(),
    'Get LCA Plot CRREM Non Renewable Energy Error': props<{ error: unknown }>(),
    'Get LCA Plot Annual Energy Success': props<{ base64: string }>(),
    'Get LCA Plot Annual Energy Error': props<{ error: unknown }>(),
    'Get LCA Plot GEAK Success': props<{ base64: string }>(),
    'Get LCA Plot GEAK Error': props<{ error: unknown }>(),
    'Estimate Geo Metrics': props<{ data: RealEstateDTO }>(),
    'Estimate Geo Metrics Success': props<{ data: RealEstateDTO }>(),
    'Estimate Geo Metrics Error': props<{ error: unknown }>(),
  },
});
