import { Injectable } from '@angular/core';
import { Logger } from '@shared';
import { UsageTypeKvUsed } from '@generated/extended';
// import usage_quantiles from './../../preset/json_data/usage_quantiles.json';

const log = new Logger('UsageQuantilesService');

export type UsageQuantileMap = {
  [index in UsageTypeKvUsed]: number[][];
};

@Injectable({
  providedIn: 'root',
})
export class UsageQuantilesService {
  public getMapping(): UsageQuantileMap {
    return this.mapping;
  }

  private mapping: UsageQuantileMap = {
    BUERO_HANDELSBAUTEN__BVB: [],
    BUERO_HANDELSBAUTEN__HANDELSBAU: [],
    FREIZEIT_SPORT_ERHOLUNGSBAUTEN: [],
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__TITLE: [],
    GESUNDHEITSBAUTEN__TITLE: [],
    JUSTIZBAUTEN__TITLE: [],
    KULTUR__TITLE: [],
    KULTUS__TITLE: [],
    LAGERBAUTEN__TITLE: [],
    LAGER_PRODUKTION__PRODUKTION: [],
    LANDWIRTSCHAFT__TITLE: [],
    SCHULBAUTEN__BIBLIOTHEKEN: [
      [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58],
      [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58],
      [1.41, 1.41, 2.75, 4.1, 5.01, 6.13, 6.13],
      [2.32, 2.32, 3.29, 4.09, 4.84, 5.72, 5.72],
      [0.0, 0.2, 0.72, 1.08, 1.51, 2.44, 2.44],
      [3.17, 3.17, 3.72, 4.08, 4.47, 5.26, 5.26],
      [1.46, 1.46, 2.89, 4.18, 5.04, 6.41, 6.41],
      [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58],
      [1.17, 1.17, 1.72, 2.08, 2.47, 3.26, 3.26],
      [1.28, 1.28, 2.16, 2.91, 3.71, 4.68, 4.68],
    ],
    SCHULBAUTEN__FORSCHUNGSBAUTEN_MIT_LABOR: [],
    SCHULBAUTEN__FORSCHUNGSBAUTEN_OHNE_LABOR: [
      [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58],
      [1.0, 1.16, 1.73, 2.12, 2.56, 3.62, 6.0],
      [0.0, 0.83, 2.29, 2.85, 3.29, 4.25, 7.0],
      [0.88, 0.88, 2.34, 2.88, 3.32, 4.4, 7.0],
      [0.0, 0.31, 1.31, 2.7, 4.62, 6.39, 7.0],
      [2.0, 3.32, 4.27, 4.77, 5.17, 5.72, 7.0],
      [0.83, 0.83, 2.9, 4.12, 5.09, 6.45, 6.45],
      [1.0, 1.16, 1.72, 2.1, 2.52, 3.4, 5.0],
      [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58],
      [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58],
    ],
    SCHULBAUTEN__TITLE: [],
    SCHUTZBAUTEN__TITLE: [],
    VERKEHRSBAUTEN__TITLE: [],
    WOHNBAUTEN__HEIMSTAETTEN_KINDERHEIME_JUGENDHEIME: [],
    WOHNBAUTEN__TITLE: [],
    BUERO_HANDELSBAUTEN__BUEROBAUTEN__BEHOERDENHAEUSER__GEMEINDEHAEUSER: [
      [2.0, 3.24, 4.3, 4.79, 5.16, 5.67, 7.0],
      [1.0, 1.79, 2.58, 2.96, 3.32, 4.0, 6.0],
      [0.0, 1.09, 2.44, 3.27, 4.19, 6.07, 7.0],
      [2.0, 2.63, 3.5, 3.92, 4.31, 5.08, 7.0],
      [0.55, 0.55, 1.88, 3.19, 4.34, 5.76, 7.0],
      [3.5, 3.5, 4.4, 4.85, 5.21, 5.83, 7.0],
      [1.0, 1.22, 3.09, 4.48, 5.35, 6.8, 7.0],
      [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58],
      [1.0, 2.22, 3.31, 4.07, 4.78, 5.69, 6.0],
      [1.0, 1.56, 2.84, 3.81, 4.76, 5.95, 6.0],
    ],
    BUERO_HANDELSBAUTEN__BUEROBAUTEN__BEHOERDENHAEUSER__RATHAUS_STADTHAUS_STADTVERWALTUNG: [
      [2.0, 2.82, 4.6, 5.39, 6.14, 6.83, 7.0],
      [1.0, 2.63, 3.64, 4.05, 4.48, 5.36, 7.0],
      [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58],
      [2.0, 3.07, 3.71, 4.1, 4.54, 5.5, 7.0],
      [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58],
      [3.0, 3.09, 4.71, 5.44, 6.16, 6.84, 7.0],
      [1.0, 2.0, 3.14, 4.19, 5.14, 6.5, 7.0],
      [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58],
      [3.42, 3.42, 4.39, 5.0, 5.61, 6.58, 6.58],
      [1.0, 1.03, 2.34, 3.74, 5.22, 6.52, 6.52],
    ],
    BUERO_HANDELSBAUTEN__BVB__BUERO: [
      [2.0, 2.09, 3.44, 4.31, 5.22, 6.49, 7.0],
      [1.4, 1.4, 2.22, 3.0, 3.94, 5.94, 6.0],
      [1.72, 1.72, 2.82, 3.81, 4.94, 5.92, 7.0],
      [2.0, 2.18, 3.32, 4.26, 5.27, 6.62, 7.0],
      [0.58, 0.58, 1.59, 2.51, 3.5, 5.38, 6.0],
      [3.77, 3.77, 4.74, 5.34, 5.95, 6.69, 7.0],
      [0.66, 0.66, 2.58, 4.38, 5.26, 6.68, 7.0],
      [0.92, 0.92, 1.65, 2.06, 2.5, 3.37, 5.0],
      [1.0, 1.1, 2.33, 3.41, 4.36, 5.79, 6.0],
      [1.0, 1.07, 2.16, 3.32, 4.59, 5.63, 6.0],
    ],
    BUERO_HANDELSBAUTEN__BVB__BUERO__EDELROHBAU: [
      [2.0, 2.42, 3.48, 4.1, 4.81, 6.15, 7.0],
      [0.93, 0.93, 2.3, 3.11, 4.1, 6.11, 6.11],
      [0.86, 0.86, 2.12, 3.15, 4.42, 5.75, 7.0],
      [2.0, 3.09, 3.72, 4.11, 4.55, 5.53, 7.0],
      [1.0, 1.9, 2.66, 3.04, 3.43, 4.35, 6.0],
      [2.42, 2.42, 3.55, 4.25, 5.1, 6.35, 7.0],
      [1.0, 2.56, 3.56, 4.17, 4.84, 6.53, 7.0],
      [1.0, 1.15, 1.7, 2.05, 2.42, 3.17, 5.0],
      [0.75, 0.75, 1.64, 2.26, 2.99, 4.49, 6.0],
      [0.61, 0.61, 1.56, 2.17, 2.93, 4.51, 6.0],
    ],
    BUERO_HANDELSBAUTEN__BVB__BUERO__VOLLAUSBAU: [
      [1.49, 1.49, 3.09, 4.04, 4.97, 6.34, 7.0],
      [0.91, 0.91, 2.2, 3.02, 3.89, 5.56, 6.0],
      [0.0, 0.86, 2.28, 3.14, 4.11, 5.71, 7.0],
      [1.0, 1.3, 2.92, 3.95, 5.0, 6.46, 7.0],
      [0.65, 0.65, 1.96, 2.93, 3.96, 5.63, 7.0],
      [1.67, 1.67, 4.02, 4.81, 5.4, 6.46, 7.0],
      [0.83, 0.83, 2.98, 4.37, 5.38, 6.85, 7.0],
      [0.6, 0.6, 1.65, 2.06, 2.53, 4.52, 6.0],
      [0.7, 0.7, 2.1, 3.1, 4.12, 5.75, 6.0],
      [1.0, 1.19, 2.76, 3.85, 4.87, 6.27, 6.27],
    ],
    BUERO_HANDELSBAUTEN__BVB__VERWALTUNG_BANK__BANK: [
      [2.0, 2.72, 4.02, 4.69, 5.25, 6.01, 7.0],
      [1.0, 1.65, 2.94, 3.94, 4.87, 6.01, 7.0],
      [1.0, 1.21, 2.67, 3.83, 4.97, 6.53, 7.0],
      [2.0, 2.45, 3.49, 4.23, 4.95, 6.03, 7.0],
      [0.93, 0.93, 2.25, 3.11, 4.02, 5.82, 6.0],
      [3.0, 3.12, 4.3, 4.87, 5.35, 6.15, 7.0],
      [1.0, 1.36, 3.64, 4.99, 5.86, 6.88, 7.0],
      [1.0, 1.57, 2.42, 2.92, 3.4, 4.17, 5.0],
      [1.0, 2.62, 3.53, 3.99, 4.44, 5.21, 6.0],
      [1.0, 2.44, 3.47, 4.04, 4.6, 5.47, 6.0],
    ],
    BUERO_HANDELSBAUTEN__BVB__VERWALTUNG_BANK__VERWALTUNG: [
      [1.72, 1.72, 3.43, 4.48, 5.4, 6.42, 7.0],
      [0.87, 0.87, 2.07, 3.0, 4.14, 6.05, 6.05],
      [1.44, 1.44, 2.88, 3.91, 5.04, 6.47, 7.0],
      [2.0, 2.12, 3.75, 4.71, 5.45, 6.36, 7.0],
      [0.61, 0.61, 1.89, 3.06, 4.26, 5.96, 6.0],
      [3.68, 3.68, 4.99, 5.66, 6.19, 6.78, 7.0],
      [1.0, 1.57, 3.28, 4.44, 5.53, 6.81, 7.0],
      [0.82, 0.82, 1.59, 2.07, 2.56, 3.38, 5.0],
      [1.0, 1.91, 3.24, 3.98, 4.7, 5.72, 6.0],
      [1.0, 1.38, 2.85, 3.82, 4.76, 6.18, 6.18],
    ],
    // BUERO_HANDELSBAUTEN__GROSSHANDEL__GROSSHANDELSBAU_GROSSMARKT: [
    //   [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
    //   [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
    //   [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
    //   [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
    //   [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
    //   [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
    //   [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
    //   [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
    //   [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
    //   [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
    // ],
    BUERO_HANDELSBAUTEN__GROSSHANDEL__WARENHAUS_EINKAUFSZENTRUM: [
      [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58],
      [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58],
      [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58],
      [1.0, 1.07, 3.3, 6.5, 6.78, 6.97, 7.0],
      [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58],
      [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58],
      [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58],
      [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58],
      [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58],
      [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58],
    ],
    BUERO_HANDELSBAUTEN__HANDELSBAU__EINZELHANDEL__LADENBAU: [
      [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58],
      [2.15, 2.15, 2.71, 3.08, 3.48, 4.27, 4.27],
      [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58],
      [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58],
      [1.0, 1.61, 2.64, 3.02, 3.42, 4.47, 5.0],
      [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58],
      [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58],
      [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58],
      [1.0, 1.0, 2.27, 2.8, 3.22, 3.81, 3.81],
      [0.0, 0.74, 2.52, 2.93, 3.29, 3.85, 3.85],
    ],
    BUERO_HANDELSBAUTEN__HANDELSBAU__MARKT_MESSE__MARKT_MESSEHALLE: [
      [1.0, 2.5, 3.47, 3.9, 4.29, 4.91, 6.0],
      [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58],
      [0.0, 0.23, 1.82, 3.23, 4.22, 5.91, 5.91],
      [0.0, 0.41, 0.89, 1.3, 1.85, 3.07, 7.0],
      [0.0, 0.42, 1.58, 3.1, 4.55, 5.98, 6.0],
      [0.46, 0.46, 1.7, 3.24, 4.66, 5.94, 5.94],
      [0.0, 0.64, 1.47, 1.9, 2.28, 3.67, 5.0],
      [0.44, 0.44, 1.3, 2.04, 2.78, 3.76, 4.0],
      [0.7, 0.7, 1.5, 1.91, 2.28, 2.86, 3.0],
      [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58],
    ],
    FREIZEIT_SPORT_ERHOLUNGSBAUTEN__GEB_SPORTAUSSENANLAGEN___GARDEROBENGEBAEUDE: [
      [0.0, 1.0, 2.26, 3.12, 4.11, 5.8, 6.0],
      [0.65, 0.65, 1.49, 2.05, 2.64, 3.66, 5.0],
      [0.0, 0.63, 2.09, 3.11, 4.21, 6.1, 6.1],
      [0.54, 0.54, 1.41, 2.05, 2.71, 3.85, 7.0],
      [0.54, 0.54, 1.82, 2.9, 4.3, 6.35, 7.0],
      [2.0, 3.12, 3.73, 4.11, 4.54, 5.4, 6.0],
      [2.0, 2.59, 4.22, 4.8, 5.25, 6.4, 7.0],
      [2.0, 2.97, 5.44, 5.83, 6.11, 6.47, 6.47],
      [1.0, 1.48, 2.73, 3.84, 4.77, 5.99, 5.99],
      [1.48, 1.48, 2.9, 4.13, 5.03, 6.18, 6.18],
    ],
    FREIZEIT_SPORT_ERHOLUNGSBAUTEN__HALLENBAD: [
      [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58],
      [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58],
      [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58],
      [3.42, 3.42, 4.39, 5.0, 5.61, 6.58, 6.58],
      [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58],
      [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58],
      [5.42, 5.42, 6.39, 7.0, 7.0, 7.0, 7.0],
      [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58],
      [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58],
      [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58],
    ],
    FREIZEIT_SPORT_ERHOLUNGSBAUTEN__SPORT_FREIZEITBAU__TURN_SPORTHALLEN__TURNHALLE: [
      [0.0, 1.7, 3.17, 4.18, 5.1, 6.29, 6.29],
      [0.49, 0.49, 1.54, 2.25, 3.32, 5.09, 5.09],
      [0.0, 0.62, 2.17, 3.2, 4.22, 5.69, 6.0],
      [0.63, 0.63, 2.0, 3.06, 4.35, 6.48, 7.0],
      [0.82, 0.82, 2.07, 2.95, 3.85, 5.84, 7.0],
      [2.0, 2.47, 3.52, 4.13, 4.74, 5.58, 6.0],
      [1.49, 1.49, 2.89, 4.32, 5.16, 6.57, 7.0],
      [1.45, 1.45, 2.36, 3.0, 3.66, 4.91, 6.0],
      [1.0, 1.29, 2.13, 2.75, 3.39, 4.5, 5.0],
      [2.0, 2.16, 3.66, 4.69, 5.39, 6.31, 6.31],
    ],
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__BEHERBERGUNGSSTAETTE__HERBERGE: [
      [0.0, 1.26, 2.17, 2.89, 3.68, 4.76, 7.0],
      [1.0, 1.32, 2.87, 3.84, 4.95, 6.68, 7.0],
      [0.0, 0.33, 1.94, 3.16, 4.5, 6.04, 7.0],
      [0.93, 0.93, 2.3, 3.1, 4.05, 6.09, 7.0],
      [1.0, 1.34, 2.84, 4.1, 5.01, 6.16, 7.0],
      [2.0, 3.17, 3.72, 4.08, 4.47, 5.37, 6.0],
      [0.0, 2.52, 4.16, 4.72, 5.13, 5.95, 6.0],
      [2.0, 2.6, 3.59, 4.2, 4.88, 6.08, 6.08],
      [1.0, 2.33, 3.36, 4.13, 4.86, 5.8, 6.0],
      [2.0, 2.26, 3.31, 3.95, 4.57, 5.51, 6.0],
    ],
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__BEHERBERGUNGSSTAETTE__HOTEL: [
      [0.0, 1.5, 2.73, 3.79, 4.68, 5.96, 7.0],
      [1.0, 1.69, 2.51, 2.93, 3.32, 4.03, 7.0],
      [0.0, 0.82, 2.12, 2.85, 3.51, 4.66, 7.0],
      [1.0, 1.43, 2.35, 3.0, 3.67, 4.68, 7.0],
      [1.0, 1.24, 2.69, 4.02, 5.11, 6.39, 7.0],
      [2.0, 3.02, 4.22, 4.74, 5.14, 5.62, 6.0],
      [0.0, 1.35, 2.97, 4.23, 5.14, 6.64, 6.64],
      [2.0, 2.57, 4.05, 4.74, 5.24, 5.89, 6.0],
      [1.0, 2.19, 3.29, 4.02, 4.76, 5.98, 6.0],
      [1.0, 1.78, 3.04, 3.78, 4.43, 5.45, 6.0],
    ],
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__VERPFLEGUNGSEINRICHTUNG__PERSONAL_RESTAURANT: [
      [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58],
      [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58],
      [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58],
      [3.42, 3.42, 4.39, 5.0, 5.61, 6.58, 6.58],
      [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58],
      [3.42, 3.42, 4.39, 5.0, 5.61, 6.58, 6.58],
      [3.42, 3.42, 4.39, 5.0, 5.61, 6.58, 6.58],
      [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58],
      [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58],
      [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58],
    ],
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__VERPFLEGUNGSEINRICHTUNG__RESTUARATION: [
      [0.0, 0.69, 2.34, 3.6, 4.83, 6.61, 7.0],
      [1.2, 1.2, 2.76, 3.91, 5.21, 6.68, 7.0],
      [0.0, 0.58, 2.03, 3.21, 4.39, 5.72, 7.0],
      [1.09, 1.09, 2.76, 4.08, 5.33, 6.67, 7.0],
      [1.0, 1.73, 3.85, 4.75, 5.44, 6.44, 7.0],
      [2.65, 2.65, 3.96, 4.65, 5.3, 6.13, 6.13],
      [0.0, 2.06, 3.85, 4.66, 5.48, 6.69, 6.69],
      [2.0, 3.59, 4.51, 4.9, 5.23, 5.94, 6.0],
      [2.15, 2.15, 3.86, 4.89, 5.67, 6.47, 6.47],
      [1.28, 1.28, 3.05, 4.48, 5.55, 6.56, 6.56],
    ],
    GESUNDHEITSBAUTEN__KRANKENHAUS_SPITAL: [
      [2.0, 3.04, 3.69, 4.03, 4.39, 5.22, 7.0],
      [1.0, 1.12, 2.18, 2.94, 3.78, 5.19, 5.19],
      [0.0, 0.86, 2.24, 3.14, 4.11, 5.93, 6.0],
      [0.0, 2.12, 3.24, 3.85, 4.41, 5.31, 6.0],
      [0.0, 1.54, 2.77, 3.78, 4.66, 5.82, 6.0],
      [2.6, 2.6, 4.27, 5.29, 6.09, 6.82, 7.0],
      [0.0, 1.16, 3.49, 4.65, 5.9, 6.96, 7.0],
      [1.38, 1.38, 3.54, 4.93, 5.97, 6.81, 7.0],
      [1.34, 1.34, 3.39, 4.6, 5.57, 6.56, 7.0],
      [2.0, 2.16, 3.72, 4.61, 5.33, 6.23, 6.23],
    ],
    GESUNDHEITSBAUTEN__PFLEGE_REHA__PFLEGEHEIM: [
      [1.0, 1.57, 3.4, 4.47, 5.33, 6.45, 7.0],
      [1.0, 1.32, 2.2, 2.86, 3.56, 4.71, 5.0],
      [0.0, 0.84, 2.17, 3.12, 4.12, 5.68, 6.0],
      [0.0, 2.31, 3.25, 3.91, 4.58, 5.54, 6.0],
      [0.0, 2.41, 3.47, 4.06, 4.68, 5.77, 7.0],
      [2.0, 2.83, 4.18, 4.86, 5.44, 6.29, 7.0],
      [0.0, 1.84, 3.64, 4.49, 5.16, 6.35, 7.0],
      [2.0, 2.68, 4.03, 4.69, 5.22, 5.91, 7.0],
      [1.0, 2.09, 3.65, 4.58, 5.31, 6.22, 7.0],
      [2.0, 2.33, 3.3, 3.96, 4.63, 5.74, 6.0],
    ],
    GESUNDHEITSBAUTEN__UNIVERSITAETSKLINIK: [
      [1.58, 1.58, 2.6, 3.45, 5.51, 6.77, 7.0],
      [1.0, 1.62, 3.3, 3.95, 4.48, 5.39, 5.39],
      [0.0, 0.22, 3.16, 4.3, 5.0, 5.83, 6.0],
      [0.0, 0.31, 2.99, 3.78, 4.5, 5.53, 6.0],
      [0.0, 0.31, 2.92, 4.09, 4.9, 5.75, 6.0],
      [3.0, 3.42, 4.6, 5.71, 6.24, 6.83, 7.0],
      [0.0, 0.2, 3.98, 4.99, 5.67, 6.84, 7.0],
      [2.0, 2.39, 4.08, 4.92, 5.52, 6.44, 7.0],
      [2.0, 2.72, 4.27, 5.03, 5.79, 6.76, 7.0],
      [2.0, 2.48, 3.45, 4.05, 4.83, 6.37, 6.37],
    ],
    JUSTIZBAUTEN__JUSTIZVOLLZUG__STRAFVOLLZUG: [
      [2.0, 2.07, 3.25, 4.02, 4.8, 6.13, 7.0],
      [0.87, 0.87, 2.13, 3.01, 3.92, 5.34, 6.0],
      [1.91, 1.91, 3.73, 4.75, 5.52, 6.5, 7.0],
      [0.61, 0.61, 1.51, 2.09, 2.74, 4.01, 4.01],
      [0.71, 0.71, 2.02, 3.07, 4.32, 6.19, 6.19],
      [2.43, 2.43, 4.02, 4.74, 5.35, 6.25, 7.0],
      [2.0, 2.3, 3.5, 4.2, 4.87, 6.26, 6.26],
      [2.0, 2.52, 3.44, 4.0, 4.56, 5.47, 6.0],
      [1.91, 1.91, 3.1, 3.94, 4.77, 5.82, 5.82],
      [1.5, 1.5, 2.44, 3.03, 3.65, 4.82, 5.0],
    ],
    KULTUR__AUSSTELLUNGEN: [
      [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58],
      [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58],
      [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58],
      [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58],
      [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58],
      [3.42, 3.42, 4.39, 5.0, 5.61, 6.58, 6.58],
      [3.42, 3.42, 4.39, 5.0, 5.61, 6.58, 6.58],
      [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58],
      [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58],
      [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58],
    ],
    KULTUR__KULTUR_GEMEINSCHAFTSZENTREN: [
      [1.97, 1.97, 3.21, 3.83, 4.39, 5.28, 7.0],
      [0.54, 0.54, 1.35, 1.96, 2.58, 3.76, 6.0],
      [0.0, 1.26, 2.23, 3.01, 3.8, 4.82, 7.0],
      [1.0, 2.16, 3.35, 4.01, 4.65, 5.57, 6.0],
      [0.65, 0.65, 2.4, 3.77, 5.09, 6.65, 7.0],
      [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58],
      [2.0, 2.73, 3.59, 4.11, 4.64, 5.98, 6.0],
      [1.41, 1.41, 2.27, 2.91, 3.56, 4.5, 5.0],
      [0.56, 0.56, 1.86, 3.08, 4.48, 5.83, 6.0],
      [1.03, 1.03, 2.27, 3.16, 4.26, 5.55, 6.0],
    ],
    KULTUR__MUSEEN: [
      [2.0, 2.32, 4.62, 5.49, 6.23, 6.86, 7.0],
      [1.0, 1.81, 4.38, 4.86, 5.24, 6.0, 6.0],
      [0.84, 0.84, 2.3, 3.8, 5.31, 6.81, 7.0],
      [0.55, 0.55, 1.76, 2.93, 4.35, 5.85, 6.0],
      [1.0, 2.44, 3.39, 4.0, 4.61, 5.52, 7.0],
      [2.0, 3.43, 4.92, 5.6, 6.19, 6.81, 7.0],
      [2.0, 3.4, 4.99, 5.68, 6.19, 6.93, 6.93],
      [1.57, 1.57, 2.43, 2.87, 3.25, 3.86, 5.0],
      [1.0, 2.15, 3.31, 3.95, 4.57, 5.47, 5.47],
      [3.42, 3.42, 4.39, 5.0, 5.61, 6.58, 6.58],
    ],
    KULTUR__SAAL: [
      [2.0, 2.9, 4.19, 4.73, 5.14, 5.64, 7.0],
      [0.8, 0.8, 2.21, 2.81, 3.25, 3.99, 6.0],
      [0.0, 0.56, 1.56, 2.23, 3.1, 4.46, 7.0],
      [1.0, 1.57, 2.96, 3.82, 4.52, 5.66, 7.0],
      [1.0, 2.4, 3.46, 3.9, 4.29, 4.94, 7.0],
      [2.0, 3.23, 4.25, 4.75, 5.15, 5.64, 7.0],
      [2.0, 2.02, 4.09, 4.72, 5.15, 6.23, 6.23],
      [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58],
      [0.76, 0.76, 2.06, 2.85, 3.54, 4.76, 6.0],
      [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58],
    ],
    KULTUS__KIRCHGEMEINDEHAEUSER: [
      [2.0, 2.15, 3.3, 3.93, 4.53, 5.48, 5.48],
      [1.0, 1.05, 2.16, 2.92, 3.71, 4.98, 5.0],
      [0.0, 0.85, 2.15, 2.94, 3.75, 5.14, 6.0],
      [0.8, 0.8, 2.17, 3.13, 4.02, 5.36, 7.0],
      [0.82, 0.82, 2.11, 3.2, 4.36, 5.93, 7.0],
      [3.0, 3.15, 3.73, 4.12, 4.56, 5.48, 7.0],
      [2.0, 2.04, 3.16, 3.9, 4.65, 6.42, 6.42],
      [1.57, 1.57, 2.42, 2.98, 3.52, 4.38, 4.38],
      [0.83, 0.83, 2.17, 3.13, 4.3, 5.85, 6.0],
      [1.76, 1.76, 3.1, 3.99, 4.86, 6.12, 6.12],
    ],
    LAGERBAUTEN_MEHRSTOECKIGE: [
      [1.0, 1.23, 2.91, 3.96, 5.0, 6.61, 7.0],
      [0.48, 0.48, 1.46, 1.99, 2.55, 4.54, 5.0],
      [0.0, 0.16, 0.89, 1.73, 2.73, 5.13, 5.13],
      [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58],
      [0.0, 0.2, 1.0, 1.95, 3.44, 5.48, 5.48],
      [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58],
      [0.0, 0.26, 0.87, 1.4, 2.27, 6.35, 7.0],
      [0.0, 0.31, 0.9, 1.42, 2.2, 4.77, 4.77],
      [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58],
      [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58],
    ],
    LAGERBAUTEN__INDUSTRIEBAUTEN_GEWERBEBAUTEN__LAGERHALLEN: [
      [1.0, 1.07, 2.21, 2.89, 3.54, 4.62, 7.0],
      [0.37, 0.37, 1.2, 1.9, 2.67, 4.35, 5.0],
      [0.0, 0.14, 0.9, 1.92, 2.98, 4.96, 5.0],
      [0.0, 0.16, 0.95, 1.86, 2.93, 5.05, 6.0],
      [0.0, 0.11, 0.73, 1.59, 2.83, 5.28, 5.28],
      [1.45, 1.45, 2.31, 2.95, 3.61, 4.67, 7.0],
      [0.0, 0.04, 0.24, 0.52, 0.96, 3.77, 7.0],
      [0.0, 0.24, 0.77, 1.2, 1.75, 2.94, 4.0],
      [0.0, 0.3, 0.8, 1.18, 1.67, 2.75, 4.0],
      [0.0, 0.04, 0.23, 0.52, 0.98, 2.31, 5.0],
    ],
    LAGERBAUTEN__VERTEILZENTRALEN: [
      [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58],
      [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58],
      [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58],
      [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58],
      [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58],
      [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58],
      [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58],
      [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58],
      [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58],
      [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58],
    ],
    LAGER_PRODUKTION__PRODUKTION__INDUSTRIEHALLEN: [
      [1.0, 1.7, 2.53, 2.95, 3.34, 4.26, 7.0],
      [0.98, 0.98, 1.65, 1.98, 2.3, 2.93, 5.0],
      [0.0, 0.25, 1.26, 2.17, 3.22, 4.7, 6.0],
      [0.0, 0.45, 0.87, 1.22, 1.69, 3.43, 7.0],
      [0.0, 0.35, 1.22, 2.0, 2.91, 4.35, 6.0],
      [1.69, 1.69, 2.52, 3.04, 3.59, 4.62, 6.0],
      [0.0, 0.33, 1.37, 2.03, 2.72, 5.46, 6.0],
      [0.0, 0.22, 0.8, 1.32, 2.0, 3.47, 5.0],
      [0.0, 0.68, 1.49, 2.0, 2.52, 3.36, 5.0],
      [0.0, 0.36, 0.82, 1.16, 1.61, 3.51, 6.0],
    ],
    LAGER_PRODUKTION__PRODUKTION__PRODUKTIONSBAUTEN: [
      [1.0, 1.03, 2.18, 3.03, 3.98, 5.35, 7.0],
      [0.54, 0.54, 1.44, 2.04, 2.69, 3.91, 4.0],
      [0.0, 1.05, 1.7, 2.08, 2.5, 3.39, 4.0],
      [0.0, 1.39, 3.1, 3.91, 4.71, 6.01, 6.01],
      [0.0, 0.28, 1.16, 1.92, 2.78, 4.24, 4.24],
      [2.0, 2.45, 3.45, 3.98, 4.5, 5.32, 5.32],
      [0.0, 0.79, 2.48, 4.22, 5.18, 6.63, 6.63],
      [0.0, 0.58, 1.41, 1.92, 2.41, 3.2, 3.2],
      [0.0, 0.76, 1.55, 1.93, 2.29, 2.85, 3.0],
      [0.0, 0.68, 1.51, 1.96, 2.4, 3.12, 3.12],
    ],
    LANDWIRTSCHAFT__GEB_TIERHALTUNG_PRODUKTION__STALLUNG: [
      [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58],
      [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58],
      [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58],
      [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58],
      [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58],
      [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58],
      [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
      [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58],
      [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58],
      [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
    ],
    LANDWIRTSCHAFT__SCHEUNEN: [
      [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58],
      [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58],
      [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58],
      [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58],
      [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58],
      [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58],
      [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
      [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
      [0.0, 0.0, 0.39, 1.0, 1.61, 2.58, 2.58],
      [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
    ],
    // SCHULBAUTEN__AULA: [
    //   [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
    //   [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
    //   [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
    //   [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
    //   [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
    //   [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
    //   [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
    //   [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
    //   [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
    //   [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
    // ],
    SCHULBAUTEN__BERUFSSCHULEN__BERUF: [
      [0.84, 0.84, 2.26, 3.28, 4.28, 5.96, 7.0],
      [1.0, 1.54, 2.52, 2.9, 3.2, 3.73, 5.0],
      [0.0, 0.42, 2.12, 3.43, 4.81, 6.34, 6.34],
      [1.0, 1.79, 3.19, 4.11, 5.02, 6.37, 7.0],
      [0.0, 1.62, 2.96, 3.81, 4.62, 5.77, 7.0],
      [1.0, 3.71, 4.53, 4.9, 5.2, 5.85, 6.0],
      [1.0, 1.42, 3.13, 4.41, 5.23, 6.6, 6.6],
      [1.2, 1.2, 1.75, 2.12, 2.55, 3.46, 5.0],
      [0.0, 1.91, 3.03, 3.87, 4.85, 6.14, 7.0],
      [0.0, 1.83, 3.07, 3.91, 4.73, 5.85, 7.0],
    ],
    SCHULBAUTEN__BERUFSSCHULEN__GYM: [
      [1.43, 1.43, 2.37, 3.05, 3.74, 4.84, 7.0],
      [1.0, 1.03, 2.18, 2.99, 3.92, 5.35, 5.35],
      [0.0, 0.42, 2.02, 3.26, 4.74, 6.24, 6.24],
      [1.0, 1.89, 3.28, 4.07, 4.8, 5.83, 7.0],
      [0.0, 1.02, 2.87, 4.05, 5.01, 6.21, 7.0],
      [1.0, 2.8, 4.04, 4.7, 5.26, 6.02, 6.02],
      [1.0, 1.14, 3.07, 4.44, 5.49, 6.84, 6.84],
      [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58],
      [0.0, 1.69, 3.07, 4.0, 4.9, 6.19, 7.0],
      [0.0, 1.69, 2.93, 3.79, 4.75, 6.16, 7.0],
    ],
    // SCHULBAUTEN__BIBLIOTHEKEN__BIBLIOTHEK: [
    //   [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
    //   [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
    //   [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
    //   [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
    //   [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
    //   [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
    //   [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
    //   [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
    //   [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
    //   [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
    // ],
    // SCHULBAUTEN__BIBLIOTHEKEN__STAATSARCHIV: [
    //   [0.0, 0.0, 0.0, 0.0, 0.61, 1.58, 1.58],
    //   [1.0, 1.13, 1.75, 2.16, 2.67, 4.44, 5.0],
    //   [0.0, 1.48, 4.22, 4.84, 5.27, 5.93, 6.0],
    //   [1.0, 2.25, 4.27, 4.84, 5.26, 5.92, 7.0],
    //   [0.0, 0.05, 0.32, 0.91, 3.3, 5.7, 7.0],
    //   [2.0, 3.08, 3.73, 4.14, 4.63, 5.9, 6.0],
    //   [1.0, 1.16, 2.17, 3.19, 4.67, 6.3, 6.3],
    //   [1.17, 1.17, 1.73, 2.11, 2.55, 3.69, 5.0],
    //   [0.0, 1.17, 1.79, 2.24, 3.04, 5.57, 7.0],
    //   [0.0, 1.18, 1.78, 2.22, 2.89, 5.41, 7.0],
    // ],
    SCHULBAUTEN__FORSCHUNGSBAUTEN: [
      [2.12, 2.12, 2.66, 3.0, 3.34, 3.88, 3.88],
      [1.3, 1.3, 1.98, 2.5, 3.03, 3.7, 3.7],
      [0.36, 0.36, 0.99, 1.51, 2.03, 2.71, 2.71],
      [0.36, 0.36, 0.99, 1.51, 2.03, 2.71, 2.71],
      [0.36, 0.36, 1.01, 3.5, 5.99, 6.64, 6.64],
      [3.3, 3.3, 3.97, 4.5, 5.02, 5.7, 5.7],
      [0.4, 0.4, 1.13, 2.45, 3.08, 4.06, 4.06],
      [1.3, 1.3, 1.98, 2.5, 3.03, 3.7, 3.7],
      [1.12, 1.12, 1.66, 2.0, 2.34, 2.87, 2.87],
      [1.12, 1.12, 1.66, 2.0, 2.34, 2.87, 2.87],
    ],
    SCHULBAUTEN__HEILPAEDAGOGISCHE_SONDERSCHULEN: [
      [2.0, 2.99, 4.38, 4.86, 5.25, 6.04, 7.0],
      [1.0, 2.03, 2.67, 3.04, 3.42, 4.25, 5.0],
      [0.0, 1.57, 2.48, 2.92, 3.31, 4.18, 6.0],
      [1.0, 1.82, 3.2, 3.91, 4.54, 5.62, 7.0],
      [0.0, 0.97, 2.85, 3.7, 4.39, 5.4, 7.0],
      [2.0, 3.16, 3.72, 4.08, 4.48, 5.42, 6.0],
      [3.42, 3.42, 4.39, 5.0, 5.61, 6.58, 6.58],
      [1.6, 1.6, 2.45, 2.9, 3.3, 4.01, 5.0],
      [0.0, 0.52, 2.78, 3.89, 4.85, 6.38, 7.0],
      [0.0, 0.48, 2.58, 3.73, 4.97, 6.41, 7.0],
    ],
    SCHULBAUTEN__HOCHSCHULEN_UNIVERSITAETEN: [
      [0.84, 0.84, 2.23, 3.22, 4.37, 6.05, 7.0],
      [1.23, 1.23, 2.25, 3.0, 3.82, 5.18, 5.18],
      [0.0, 0.56, 2.05, 3.26, 4.58, 6.09, 6.09],
      [1.86, 1.86, 3.1, 4.02, 4.89, 6.09, 7.0],
      [1.0, 1.07, 2.79, 3.85, 4.82, 6.18, 6.18],
      [2.22, 2.22, 3.96, 4.91, 5.64, 6.46, 6.46],
      [1.54, 1.54, 3.59, 4.69, 5.45, 6.79, 6.79],
      [0.67, 0.67, 1.6, 2.19, 2.86, 4.27, 5.0],
      [1.0, 1.34, 3.0, 4.11, 5.0, 6.15, 6.15],
      [1.59, 1.59, 2.89, 3.9, 4.84, 6.09, 6.09],
    ],
    SCHULBAUTEN__KINDERGAERTEN_HORTE: [
      [1.74, 1.74, 2.95, 3.82, 4.71, 6.0, 7.0],
      [1.0, 1.4, 2.38, 2.96, 3.54, 4.62, 5.0],
      [0.0, 0.5, 1.99, 3.01, 4.22, 5.87, 6.0],
      [1.0, 1.4, 2.43, 3.17, 3.94, 5.25, 7.0],
      [0.0, 1.11, 2.66, 3.85, 4.89, 6.33, 7.0],
      [2.0, 2.34, 3.51, 4.22, 4.94, 6.06, 6.06],
      [1.0, 1.09, 2.94, 4.31, 5.17, 6.68, 6.68],
      [1.39, 1.39, 2.25, 2.88, 3.52, 4.67, 5.0],
      [0.0, 1.55, 3.04, 4.08, 5.02, 6.24, 7.0],
      [0.0, 1.41, 2.79, 3.78, 4.84, 6.26, 7.0],
    ],
    SCHULBAUTEN__VOLKSSCHULEN: [
      [1.67, 1.67, 2.93, 3.86, 4.79, 6.24, 7.0],
      [1.0, 1.15, 2.2, 2.92, 3.69, 5.05, 5.05],
      [0.0, 0.62, 2.08, 3.17, 4.51, 6.03, 6.03],
      [1.0, 1.58, 3.0, 3.9, 4.76, 6.03, 7.0],
      [0.0, 0.71, 2.08, 3.18, 4.34, 5.96, 7.0],
      [1.0, 2.75, 3.75, 4.38, 5.06, 6.16, 6.16],
      [1.0, 1.24, 2.72, 4.03, 5.02, 6.53, 6.53],
      [1.07, 1.07, 1.78, 2.27, 2.87, 4.12, 5.0],
      [0.0, 1.65, 3.01, 4.05, 4.99, 6.14, 7.0],
      [0.0, 1.56, 2.84, 3.81, 4.79, 6.07, 7.0],
    ],
    SCHUTZBAUTEN__FEUERWEHR: [
      [1.98, 1.98, 3.73, 4.56, 5.17, 5.91, 7.0],
      [0.97, 0.97, 2.19, 2.87, 3.51, 4.56, 4.56],
      [0.0, 0.52, 2.1, 2.97, 3.91, 5.81, 6.0],
      [1.0, 1.59, 3.29, 3.82, 4.22, 4.79, 4.79],
      [0.32, 0.32, 1.41, 2.48, 3.8, 6.36, 7.0],
      [1.0, 1.55, 3.57, 4.64, 5.43, 6.37, 6.37],
      [1.0, 1.66, 3.03, 3.74, 4.35, 5.7, 5.7],
      [0.88, 0.88, 2.03, 2.93, 4.08, 5.51, 5.51],
      [1.72, 1.72, 2.56, 2.93, 3.26, 3.84, 5.0],
      [0.0, 0.43, 1.32, 2.04, 2.81, 4.67, 5.0],
    ],
    VERKEHRSBAUTEN__HOCH_TIEFGARAGE__HOCHGARAGE: [
      [0.66, 0.66, 2.43, 3.76, 4.99, 6.45, 6.45],
      [0.25, 0.25, 1.23, 2.22, 3.5, 5.7, 6.0],
      [1.0, 1.85, 2.62, 3.07, 3.56, 5.23, 6.0],
      [0.0, 0.07, 0.48, 1.13, 2.36, 4.19, 4.19],
      [0.0, 0.12, 0.69, 1.46, 2.48, 4.23, 4.23],
      [0.0, 0.49, 1.39, 1.91, 2.39, 3.22, 4.0],
      [0.0, 0.03, 0.17, 0.37, 0.69, 3.57, 4.0],
      [0.0, 0.05, 0.3, 0.67, 1.29, 2.49, 2.49],
      [0.0, 0.25, 0.77, 1.14, 1.61, 2.65, 5.0],
      [0.0, 0.17, 0.72, 1.24, 1.88, 3.07, 3.07],
    ],
    VERKEHRSBAUTEN__HOCH_TIEFGARAGE__TIEFGARAGE: [
      [1.0, 1.54, 3.2, 4.02, 4.88, 6.17, 6.17],
      [1.62, 1.62, 2.56, 3.2, 3.94, 5.34, 6.0],
      [0.69, 0.69, 2.27, 3.33, 4.67, 6.09, 6.09],
      [0.0, 0.09, 0.56, 1.3, 2.68, 4.67, 4.67],
      [0.0, 0.43, 1.5, 2.15, 2.86, 4.14, 4.14],
      [0.0, 0.79, 1.61, 2.09, 2.62, 3.61, 4.0],
      [0.0, 0.15, 0.76, 1.41, 2.23, 5.18, 5.18],
      [0.0, 0.32, 0.82, 1.2, 1.69, 2.61, 2.61],
      [0.0, 0.18, 0.89, 1.62, 2.46, 4.93, 5.0],
      [0.0, 0.42, 0.88, 1.25, 1.74, 2.59, 2.59],
    ],
    VERKEHRSBAUTEN__HOCH_TIEFGARAGE__TIEFGARAGE__PARKHAUS: [
      [1.0, 3.05, 4.39, 4.89, 5.31, 6.2, 7.0],
      [3.42, 3.42, 4.39, 5.0, 5.61, 6.58, 6.58],
      [0.0, 3.03, 5.11, 5.69, 6.11, 6.58, 7.0],
      [0.0, 0.04, 0.27, 0.6, 1.13, 3.36, 7.0],
      [0.0, 1.06, 1.75, 2.18, 2.76, 4.25, 5.0],
      [0.0, 0.91, 2.49, 2.98, 3.43, 4.88, 7.0],
      [0.0, 0.53, 1.51, 1.93, 2.32, 4.11, 6.0],
      [0.0, 0.63, 1.5, 1.92, 2.3, 2.91, 4.0],
      [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58],
      [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58],
    ],
    VERKEHRSBAUTEN__WERKHOEFE: [
      [2.74, 2.74, 3.93, 4.59, 5.12, 5.75, 5.75],
      [0.46, 0.46, 1.49, 2.19, 2.96, 4.55, 6.0],
      [0.0, 0.47, 2.16, 3.28, 4.1, 5.42, 5.42],
      [1.72, 1.72, 2.57, 3.1, 3.66, 4.66, 5.0],
      [0.46, 0.46, 1.5, 2.23, 3.01, 4.53, 6.0],
      [1.53, 1.53, 2.49, 3.12, 3.77, 4.77, 5.0],
      [0.68, 0.68, 1.6, 2.21, 2.95, 4.72, 4.72],
      [0.8, 0.8, 1.62, 2.14, 2.73, 3.93, 4.0],
      [0.94, 0.94, 1.63, 2.06, 2.51, 3.29, 3.29],
      [0.6, 0.6, 1.49, 2.04, 2.61, 3.94, 5.0],
    ],
    VERKEHRSBAUTEN__ZOLL: [
      [2.25, 2.25, 3.72, 4.66, 5.26, 6.03, 6.03],
      [1.57, 1.57, 2.42, 2.86, 3.23, 3.78, 3.78],
      [0.0, 0.25, 1.7, 2.98, 4.98, 6.48, 6.48],
      [1.57, 1.57, 2.42, 2.86, 3.23, 3.78, 3.78],
      [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58],
      [1.75, 1.75, 3.75, 4.72, 5.72, 6.7, 7.0],
      [4.62, 4.62, 5.48, 5.87, 6.2, 6.82, 6.82],
      [1.8, 1.8, 2.56, 2.94, 3.28, 3.81, 3.81],
      [0.81, 0.81, 2.86, 3.85, 4.6, 6.05, 6.05],
      [2.57, 2.57, 3.42, 3.86, 4.23, 4.78, 4.78],
    ],
    WOHNBAUTEN__ALTERSHEIME: [
      [1.0, 1.66, 3.39, 4.47, 5.36, 6.48, 7.0],
      [1.0, 1.45, 2.33, 2.96, 3.6, 4.58, 6.0],
      [0.0, 0.81, 2.13, 3.06, 4.08, 5.63, 6.0],
      [1.0, 1.34, 2.46, 3.14, 3.9, 5.29, 7.0],
      [1.0, 2.23, 3.3, 3.96, 4.6, 5.71, 7.0],
      [1.0, 2.78, 4.18, 4.73, 5.12, 5.61, 7.0],
      [1.0, 1.53, 3.46, 4.43, 5.19, 6.59, 7.0],
      [2.0, 2.95, 4.09, 4.68, 5.15, 5.72, 7.0],
      [0.0, 2.2, 3.28, 3.95, 4.62, 5.68, 7.0],
      [1.0, 2.07, 3.28, 3.85, 4.35, 5.15, 7.0],
    ],
    WOHNBAUTEN__ALTERSWOHNUNGEN: [
      [1.0, 1.82, 2.62, 3.11, 3.66, 4.84, 7.0],
      [1.0, 1.82, 2.59, 3.04, 3.52, 4.37, 6.0],
      [0.0, 1.05, 2.21, 2.97, 3.78, 5.35, 6.0],
      [1.0, 2.12, 2.7, 3.05, 3.43, 4.27, 7.0],
      [1.0, 2.28, 3.37, 3.95, 4.49, 5.39, 7.0],
      [1.0, 2.7, 3.61, 3.95, 4.27, 4.79, 7.0],
      [1.0, 1.58, 2.89, 3.79, 4.49, 6.18, 7.0],
      [2.0, 3.31, 4.3, 4.78, 5.15, 5.62, 7.0],
      [0.0, 2.09, 2.69, 3.05, 3.44, 4.32, 7.0],
      [1.0, 1.75, 2.59, 3.0, 3.43, 4.43, 7.0],
    ],
    WOHNBAUTEN__EFH_REIHEN: [
      [1.0, 1.24, 2.34, 3.1, 3.99, 5.62, 7.0],
      [1.0, 1.31, 2.38, 3.01, 3.67, 5.19, 6.0],
      [0.0, 0.39, 1.82, 2.75, 3.63, 5.23, 6.0],
      [1.0, 1.4, 2.29, 2.93, 3.6, 4.88, 7.0],
      [1.0, 1.25, 2.97, 3.81, 4.51, 5.7, 7.0],
      [1.0, 1.37, 2.46, 3.16, 3.91, 5.23, 7.0],
      [1.0, 1.56, 2.82, 3.68, 4.42, 6.69, 7.0],
      [2.0, 2.52, 3.66, 4.36, 5.03, 6.01, 7.0],
      [0.0, 0.82, 2.08, 2.98, 3.99, 5.72, 7.0],
      [0.93, 0.93, 2.1, 2.93, 3.82, 5.44, 7.0],
    ],
    // WOHNBAUTEN__HEIMSTAETTEN_KINDERHEIME_JUGENDHEIME__BEHINDERTE: [
    //   [0.85, 0.85, 2.3, 3.17, 3.97, 5.31, 7.0],
    //   [1.0, 1.31, 2.07, 2.74, 3.51, 4.71, 6.0],
    //   [0.0, 0.5, 1.69, 3.41, 4.78, 6.24, 6.24],
    //   [1.0, 1.45, 2.37, 3.26, 3.99, 4.88, 7.0],
    //   [1.0, 1.63, 2.7, 3.37, 4.05, 5.15, 7.0],
    //   [1.0, 1.66, 3.16, 4.2, 5.08, 6.24, 7.0],
    //   [0.52, 0.52, 1.6, 3.1, 4.42, 5.94, 7.0],
    //   [2.0, 2.48, 3.42, 4.19, 4.89, 5.71, 7.0],
    //   [0.0, 1.54, 2.78, 4.27, 5.04, 5.77, 7.0],
    //   [1.0, 1.53, 2.6, 3.36, 4.18, 5.36, 7.0],
    // ],
    // WOHNBAUTEN__HEIMSTAETTEN_KINDERHEIME_JUGENDHEIME__KINDER: [
    //   [0.0, 2.19, 4.22, 5.12, 5.8, 6.57, 7.0],
    //   [0.46, 0.46, 1.31, 2.6, 3.27, 4.56, 7.0],
    //   [0.0, 1.35, 4.0, 4.78, 5.22, 5.85, 7.0],
    //   [1.0, 1.35, 2.13, 3.11, 3.99, 4.88, 7.0],
    //   [1.0, 2.03, 2.99, 3.89, 4.89, 5.71, 7.0],
    //   [1.0, 2.17, 4.05, 4.77, 5.21, 5.84, 7.0],
    //   [0.0, 1.39, 2.55, 3.11, 3.75, 5.77, 7.0],
    //   [2.0, 2.19, 2.8, 3.24, 3.92, 5.45, 7.0],
    //   [0.0, 0.45, 1.3, 3.24, 4.85, 5.7, 7.0],
    //   [1.0, 1.76, 2.59, 3.01, 3.43, 4.46, 7.0],
    // ],
    WOHNBAUTEN__MFH: [
      [1.0, 1.5, 2.64, 3.38, 4.3, 5.79, 7.0],
      [1.0, 1.47, 2.49, 3.15, 3.87, 5.14, 6.0],
      [0.0, 0.65, 2.08, 3.11, 4.14, 5.61, 6.0],
      [1.0, 1.55, 2.54, 3.18, 3.91, 5.19, 7.0],
      [1.0, 1.76, 3.13, 3.91, 4.63, 5.8, 7.0],
      [1.0, 1.61, 2.53, 3.11, 3.74, 4.94, 7.0],
      [1.0, 1.13, 2.35, 3.51, 4.43, 6.27, 7.0],
      [2.0, 2.58, 3.59, 4.23, 4.87, 5.76, 7.0],
      [0.0, 1.39, 2.37, 3.05, 3.77, 5.19, 7.0],
      [1.0, 1.33, 2.28, 2.96, 3.68, 4.99, 7.0],
    ],
    WOHNBAUTEN__PERSONALHAEUSER: [
      [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58],
      [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58],
      [0.0, 1.34, 4.0, 4.77, 5.22, 5.85, 7.0],
      [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58],
      [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58],
      [1.42, 1.42, 2.39, 3.0, 3.61, 4.58, 4.58],
      [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58],
      [2.42, 2.42, 3.39, 4.0, 4.61, 5.58, 5.58],
      [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58],
      [0.42, 0.42, 1.39, 2.0, 2.61, 3.58, 3.58],
    ],
    WOHNBAUTEN__STUDENTENWOHNHAEUSER: [
      [0.0, 2.1, 3.17, 3.9, 4.67, 6.02, 7.0],
      [1.0, 2.35, 3.32, 3.9, 4.47, 5.35, 7.0],
      [0.0, 1.19, 2.34, 3.12, 3.92, 5.14, 7.0],
      [1.0, 1.0, 2.15, 2.91, 3.67, 4.81, 7.0],
      [1.0, 1.75, 3.0, 3.88, 4.76, 5.9, 7.0],
      [1.0, 1.91, 3.15, 3.9, 4.6, 5.68, 7.0],
      [0.0, 1.54, 3.29, 3.86, 4.21, 5.24, 7.0],
      [2.0, 2.64, 3.51, 4.07, 4.64, 5.5, 7.0],
      [0.0, 1.98, 3.12, 3.88, 4.68, 6.06, 7.0],
      [1.0, 1.94, 2.65, 2.98, 3.29, 3.88, 7.0],
    ],
    WOHNBAUTEN__TERRASSENHAEUSER: [
      [1.0, 2.49, 3.45, 3.88, 4.25, 4.86, 7.0],
      [1.0, 2.86, 4.42, 4.83, 5.12, 5.5, 6.0],
      [0.0, 1.61, 2.49, 2.98, 3.47, 4.35, 6.0],
      [1.0, 2.14, 2.7, 3.04, 3.4, 4.23, 7.0],
      [1.0, 2.15, 3.84, 4.62, 5.26, 6.14, 7.0],
      [1.0, 1.77, 2.56, 2.96, 3.34, 4.03, 7.0],
      [1.0, 1.85, 3.13, 3.84, 4.49, 6.09, 7.0],
      [3.42, 3.42, 4.39, 5.0, 5.61, 6.58, 6.58],
      [0.0, 1.71, 2.54, 3.06, 3.59, 4.49, 7.0],
      [1.0, 1.73, 2.57, 3.08, 3.62, 4.69, 7.0],
    ],
  };
}
