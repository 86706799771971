import { inject, Injectable } from '@angular/core';
import {
  AdditionEntryDTO,
  ComponentDTO,
  ComponentMeasureDTO,
  InvestmentDTO,
  OutRealEstateDTO,
  RealEstateUsageEmbeddable,
  RebuildEstateDTO,
} from '@generated/generatedEntities';
import { RebuildTotalMeasureService } from '../rebuild-total-measure/rebuild-total-measure.service';
import { BehaviorSubject, map, Observable, of, take } from 'rxjs';
import { Logger } from '@app/@shared';
import { RebuildEstateService } from '../rebuild-estate.service';
import { RebuildComponentSliderService } from '../rebuild-component-slider/rebuild-component-slider.service';
import { RoomHeightDirectiveService } from '@app/calculators/components/room-height-directive/room-height-directive/room-height-directive.component';
import { ComponentMeasure } from '@generated/component-measure';

const log = new Logger('RebuildEstateDataService');
@Injectable({
  providedIn: 'root',
})
/**
 * Data access with a local cache.
 */
export class RebuildEstateDataService {
  private _rebuildService = inject(RebuildEstateService);
  private _rebuildComponentSliderService = inject(RebuildComponentSliderService);
  private _rebuildTotalMeasureService = inject(RebuildTotalMeasureService);
  public _roomHeightDirectiveService = inject(RoomHeightDirectiveService);
  /**
   * @deprecated FIXME potentially this value is not always up to date - in LCC we use the LCC STORE - this value should not be used
   */
  public rebuildEstate: OutRealEstateDTO = {} as OutRealEstateDTO;
  private realEstate$ = new BehaviorSubject<OutRealEstateDTO>({} as OutRealEstateDTO);
  // public realEstateContainer = {} as RebuildEstateDTO;

  isDataLoadedResponse$: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);

  /**
   * @deprecated FIXME potentially this value is not always up to date - in LCC we use the LCC STORE - this value should not be used
   */
  getRebuildEstate() {
    // console.log('rebuildEstate for construction year', this.rebuildEstate);
    return this.rebuildEstate;
  }
  /**
   * Set rebuildEstate outside due to different loading style e.g. admin special load.
   * @param dm domain model / dto from backend.
   * @param addPrototypes if dm is still raw from backend we need to add the prototypes so ComponentMeasures works correctly.
   * @deprecated there is a change that the value is not up to date.
   */
  setRebuildEstate(dm: any, addPrototypes: boolean) {
    if (addPrototypes) {
      var rebuildEstate = this.addJsPrototypes(dm);
      this.rebuildEstate = rebuildEstate;
    } else {
      this.rebuildEstate = dm;
    }
  }

  getEmpty(): Observable<RebuildEstateDTO> {
    return this._rebuildService.getEmpty().pipe(
      map((value) => {
        // value.usages.push({} as RealEstateUsageEmbeddable);
        // value.additions.push({ label: 'Reserve', bkp: 'BKP_6_RESERVE', targetType: 'OVERHAUL' } as AdditionEntryDTO);
        // // value.additions.push({} as AdditionEntryDTO);
        // value.targetOverhaul.usages.push({} as RealEstateUsageEmbeddable);
        this.prepareRebuildEmptyObject(value);
        this.rebuildEstate = value as OutRealEstateDTO;
        this.realEstate$.next(value as OutRealEstateDTO);
        return value;
      }),
    );
  }

  getDamageEmpty(): Observable<RebuildEstateDTO> {
    return this._rebuildService.getEmptyDamage().pipe(
      map((value) => {
        // value.usages.push({} as RealEstateUsageEmbeddable);
        // value.additions.push({ label: 'Reserve', bkp: 'BKP_6_RESERVE', targetType: 'OVERHAUL' } as AdditionEntryDTO);
        // // value.additions.push({} as AdditionEntryDTO);
        // value.targetOverhaul.usages.push({} as RealEstateUsageEmbeddable);
        this.prepareRebuildEmptyObject(value);
        this.rebuildEstate = value as OutRealEstateDTO;
        this.realEstate$.next(value as OutRealEstateDTO);
        return value;
      }),
    );
  }

  getCalculateResponse(): Observable<any | null> {
    return this.isDataLoadedResponse$.asObservable();
  }

  postProcessForEditRecordById(realEstate: OutRealEstateDTO): Observable<RebuildEstateDTO> {
    console.log('realestate recieved ', realEstate);
    if (realEstate.id == null) {
      const NewRealEstateDTO = {} as RebuildEstateDTO;
      log.error('returning empty obs - no id set');
      // if (NewRealEstateDTO.additions.length === 0) {
      NewRealEstateDTO.additions = [];
      NewRealEstateDTO.additions.push({ bkp: null } as AdditionEntryDTO);
      NewRealEstateDTO.additions.push({
        label: 'reserve',
        bkp: 'BKP_6_RESERVE',
        targetType: 'OVERHAUL',
      } as AdditionEntryDTO);

      // }
      console.log('newrealDTO', NewRealEstateDTO);
      return of(NewRealEstateDTO);
    }
    this.processEditRebuildRealEstate(realEstate);

    this.realEstate$.next(realEstate);
    // this.rebuildEstate = realEstate;
    Object.assign(this.rebuildEstate, realEstate);
    console.log('rebuild postProcessForEditRecordById data', this.rebuildEstate);
    return this.getRealEstateDataObservable().pipe(take(1));
  }

  /**
   * This function doesn't store the data in the store - and just manipulates given realEstate.
   * @param realEstate
   */
  processEditRebuildRealEstate(realEstate: OutRealEstateDTO) {
    this._roomHeightDirectiveService.onChangeGeometryTotalFloorArea416TotalFloorArea416(realEstate); // Necassary for loading up the Roomheight values if any for TargetAnnex,Overhaul,Annex
    realEstate.metaData.parentRealEstateId = realEstate.metaData.parentRealEstateId
      ? realEstate.metaData.parentRealEstateId
      : realEstate.id;
    if (realEstate.metaData.name == null) {
      realEstate.metaData.name = '';
    }
    if (this.endsWith(realEstate.metaData.name, ')')) {
      var brBegin = realEstate.metaData.name.lastIndexOf('(');
      if (brBegin >= 0) {
        var txtBetweenBr = realEstate.metaData.name.substring(brBegin + 1, realEstate.metaData.name.length - 1);
        var parsedNumBetweenBr = parseInt(txtBetweenBr);
        if (parsedNumBetweenBr) {
          realEstate.metaData.name =
            realEstate.metaData.name.substring(0, brBegin - 1) + ' (' + (realEstate.metaData.numEstimations + 1) + ')';
        }
      }
    } else {
      realEstate.metaData.name = realEstate.metaData.name + ' (' + (realEstate.metaData.numEstimations + 1) + ')';
    }
    realEstate.id = null;
    if (realEstate.additions.length === 0) {
      realEstate.additions = [];
      realEstate.additions.push({ bkp: null } as AdditionEntryDTO);
    }
    if (realEstate.investments.length === 0) {
      realEstate.investments = [];
      realEstate.investments.push({} as InvestmentDTO);
    }
  }

  endsWith(str: any, suffix: any) {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
  }

  getRealEstateDataObservable(): Observable<RebuildEstateDTO> {
    return this.realEstate$.asObservable();
  }

  addJsPrototypes(rebuildEstate: any) {
    // set prototype for component measures.
    rebuildEstate.analysis.components.forEach((component: ComponentDTO) => {
      if (component.totalMeasure == null) component.totalMeasure = {} as ComponentMeasureDTO;
      if (component.totalMeasure != null) Object.setPrototypeOf(component.totalMeasure, ComponentMeasure); //ComponentMeasures.prototype
      if (component.mainModernizationMeasure == null) component.mainModernizationMeasure = {} as ComponentMeasureDTO;
      if (component.mainModernizationMeasure != null)
        Object.setPrototypeOf(component.mainModernizationMeasure, ComponentMeasure); //ComponentMeasures.prototype
      if (component.modificationMeasure != null) Object.setPrototypeOf(component.modificationMeasure, ComponentMeasure); //ComponentMeasures.prototype
      // this._rebuildComponentSliderService.setRemainingValueInterval(component);
    });
    // Recalculate total measure
    rebuildEstate.analysis.components.forEach((component: ComponentDTO) => {
      component.totalMeasure = this._rebuildTotalMeasureService.calculateTotalMeasure(component, rebuildEstate);
    });
    return rebuildEstate;
  }

  saveRebuildEstateToSession() {
    // $sessionStorage.rebuildEstate = service.rebuildEstate;

    sessionStorage.setItem('rebuildEstate', JSON.stringify(this.rebuildEstate));
  }

  checkAdditions(additions: any) {
    additions.forEach((addition: any, index: any) => {
      if (addition.bkp == null && addition.value == null && addition.label == null) {
        additions.splice(index, 1);
      }
    });
  }

  prepareRebuildEmptyObject(rebuildObj: RebuildEstateDTO) {
    rebuildObj.usages.push({
      type: null,
      percentage: null,
      standard: null,
      numFuUsage: null,
    } as RealEstateUsageEmbeddable);
    rebuildObj.additions.push({ label: 'Reserve', bkp: 'BKP_6_RESERVE', targetType: 'OVERHAUL' } as AdditionEntryDTO);
    if (rebuildObj.targetOverhaul.usages.length === 0) {
      rebuildObj.targetOverhaul.usages.push({
        type: null,
        percentage: null,
        standard: null,
        numFuUsage: null,
      } as RealEstateUsageEmbeddable);
    }
  }
}
