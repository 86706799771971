<div class="container" *transloco="let t" id="page_register">
  <mat-card class="p-0">
    <!-- <div class="content-row">
      <img class="banner-img"  src="{{ selectedImage }}" id="myRandomImg" />
      <div class="banner-row">
        <div class="banner-title-box">
          <mat-card-title id="header" class="banner-header">
            <h1>{{ t('register.title') }}</h1>
          </mat-card-title>
        </div>
        <div class="banner-desc-box">
          <mat-card-title id="header" class="banner-title">
            <div style="width: auto">
              <img height="20px" src="{{ selectedSubLogo }}" id="myRandomImg2" />
            </div>
            <div style="width: auto"></div>
            <div style="width: auto">
              <img  src="{{ selectedSubImage }}" id="myRandomImg1" />
            </div>
          </mat-card-title>
        </div>
      </div>
    </div> -->
    <app-dynamic-image [mode]="'FLEX'">
      <!--      <div class="flex-container">-->
      <!--        <h1 class="bannerTitle" style="white-space: nowrap" data-translate="register.title">-->
      <!--          {{ t('register.title') }}-->
      <!--        </h1>-->
      <!--      </div>-->
      <div class="flex-container">
        <div
          class="flexBoxOuter pageId_Register bannerFlexBox bannerModeFlex ng-star-inserted"
          style="align-items: center"
        >
          <div class="flexBoxInner img marginRight bannerTextBox" style="display: flex; align-self: flex-end">
            <h1 class="bannerTitle" style="white-space: nowrap" data-translate="register.title">
              {{ t("register.title") }}
            </h1>
          </div>
        </div>
        <div class="flexBoxInner txt" style="display: inline-block; height: 30px; align-self: flex-end"></div>
      </div>
    </app-dynamic-image>
  </mat-card>

  <mat-card class="p-0 kv-mat-card">
    <!-- sectionId_register -->
    <app-prices [showBuildingCosts]="true"></app-prices>
  </mat-card>
</div>
