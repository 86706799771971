import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _http = inject(HttpClient);

  set_public_ref_settings(currentAccount: any, showPublicRefObjects: boolean): Observable<any> {
    let data = {
      currentAccount: currentAccount,
      showPublicRefObjects: showPublicRefObjects,
    };
    return this._http.post('/api/users/settings/show_public_ref/:id', data); // check this one :: FIXME
  }
  set_private_ref_settings(currentAccount: any, showPublicRefObjects: boolean): Observable<any> {
    let data = {
      currentAccount: currentAccount,
      showPublicRefObjects: showPublicRefObjects,
    };
    return this._http.post('/api/users/settings/show_priv_ref/:id', data); // check this one :: FIXME
  }

  updateUserLanguage(language: string) {
    let splitLanguage = language.toLowerCase();
    //split language by -
    // console.log(splitLanguage.endsWith('-ch'),splitLanguage.split('-'));

    if (splitLanguage.endsWith('-ch')) {
      splitLanguage = splitLanguage.split('-')[0]; //take first part of the string without -ch.
    }
    // console.log(splitLanguage);
    return this._http.post<any>(`${environment.serverUrl}/api/users/language`, splitLanguage);
  }
  query_eligible_for_withdrawal_products(searchString: string): Observable<any> {
    return this._http.get('/api/users/query_eligible_for_withdrawal_products');
  }
  search_eligible_for_withdrawal_products_by_login(searchString: string): Observable<any> {
    return this._http.get('/api/users/search_eligible_for_withdrawal_products_by_login/' + searchString);
  }
  query_non_owner_logins(): Observable<any> {
    return this._http.get('/api/users/query_non_owner_logins');
  }
  search_by_non_owner_login(searchString: string): Observable<any> {
    return this._http.post('/api/users/search_by_non_owner_login/', searchString);
  }
  query_owner_logins(): Observable<any> {
    return this._http.get('/api/users/query_owner_logins');
  }
  search_by_owner_login(searchString: string): Observable<any> {
    return this._http.post('/api/users/search_by_owner_login/', searchString);
  }
  query_actives(): Observable<string[]> {
    return this._http.get<string[]>('/api/users/query_actives');
  }

  search_actives_by_login(searchString: string): Observable<string[]> {
    return this._http.get<string[]>('/api/users/search_actives_by_login/' + encodeURIComponent(searchString));
  }
}
