import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContactDtmDialogComponent } from '@app/calculators/components/contact-dtm-dialog/contact-dtm-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class GlobalModalService {
  private _dialog = inject(MatDialog);

  contactDtmModal(modulObj: any) {
    const dialogRef = this._dialog.open(ContactDtmDialogComponent, {
      // width: '100%',
      width: '60%',
      height: 'auto',
      maxHeight: '70vh',
      // backdropClass:'static',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: {
        modul: modulObj,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.warn('CONTACT DTM MODAL CLOSED');
      }
    });
  }
}
